import { t } from "@lingui/core/macro";
import {
  LimePageHeader,
  LimePageHeaderProps,
} from "@/Components/LimePageHeader";
import { useLimeAlertModal } from "@/Components/Modals/useLimeAlertModal";
import { Color } from "@/types/colors";
import { useForm, zodResolver } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { Tab, Tabs } from "@nextui-org/react";
import { VscTrash } from "react-icons/vsc";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { SupplierTab } from "./SupplierTab";
import dayjs from "dayjs";
import { parseDate } from "@internationalized/date";
import { useEffect, useState } from "react";
import { ProductsTab } from "./ProductsTab";
import { OverviewTab } from "./OverviewTab";
import { api } from "@/lib/api-client";
import { useQueryParamsState } from "@/hooks/useQueryParamState";

export const ManageDeliveryOrder = () => {
  const manageDeliveryOrderSchema = z.object({
    locationId: z.coerce
      .number()
      .optional()
      .refine((val) => val, { message: t`Izberite lokacijo` }),
    number: z.string().min(1, { message: t`Vnesite številko dobavnice` }),
    supplier: z.coerce
      .number()
      .optional()
      .refine((val) => val, { message: t`Izberite dobavitelja` }),
    date: z.any(),
    totalDiscountPercentage: z.coerce.number(),
    products: z
      .array(
        z.object({
          productId: z.number(),
          quantity: z.coerce.number().refine((val) => val > 0, {
            message: t`Količina izdelkov mora biti več kot 0`,
          }),
          discountPercentage: z.coerce.number(),
          netPricePer: z.coerce.number(),
          grossPricePer: z.coerce.number(),
          taxPercentage: z.coerce.number(),
        }),
      )
      .min(1, { message: t`Izberite produkt` }),
  });

  const navigate = useNavigate();

  const { deliveryOrderId } = useParams();

  const [tab, setTab] = useQueryParamsState<
    "supplier" | "products" | "overview"
  >("tab", "supplier");

  const { alertModal } = useLimeAlertModal();

  // APIS

  const { data: existingOrder, isError: isGetDeliveryOrderError } =
    api.deliveryOrder.useGetDeliveryOrderById(Number(deliveryOrderId));

  const { mutateAsync: postDeliveryOrder, isPending: isPostLoading } =
    api.deliveryOrder.usePostDeliveryOrder();

  const { mutateAsync: updateExistingOrder, isPending: isPutLoading } =
    api.deliveryOrder.usePutDeliveryOrderById();

  const { mutateAsync: deleteExistingOrder } =
    api.deliveryOrder.useDeleteDeliveryOrderById();

  //

  const [steps, setSteps] = useState([
    { key: "supplier", done: false },
    { key: "products", done: false },
    { key: "overview", done: false },
  ]);

  const deliveryOrderForm = useForm<z.infer<typeof manageDeliveryOrderSchema>>({
    initialValues: {
      number: "",
      date: parseDate(dayjs().format("YYYY-MM-DD")),
      totalDiscountPercentage: 0,
      products: [],
    },
    validate: zodResolver(manageDeliveryOrderSchema),
  });

  // neka redirection logika
  useEffect(() => {
    if (deliveryOrderId !== "-1") {
      if (isGetDeliveryOrderError) {
        setTab("supplier");
        return;
      }

      if (existingOrder) {
        deliveryOrderForm.initialize({
          number: existingOrder.deliveryOrder.orderNumber,
          date: parseDate(existingOrder.deliveryOrder.date),
          totalDiscountPercentage:
            existingOrder.deliveryOrder.discountPercentage,
          locationId: existingOrder.deliveryOrder.locationId,
          supplier: existingOrder.deliveryOrder.supplierId,
          products: existingOrder.deliveryOrder.DeliveryOrderProduct.map(
            (p) => ({
              discountPercentage: p.discountPercentage,
              taxPercentage: Number(p.taxPercentage),
              quantity: p.quantity,
              productId: p.productId,
              netPricePer: p.netPriceCents / 100,
              grossPricePer: p.grossPriceCents / 100,
            }),
          ),
        });

        setSteps((prev) => {
          return prev.map((s) => {
            return { ...s, done: true };
          });
        });
        return;
      }
      return;
    }

    if (!steps.map((s) => s.key).includes(tab!)) {
      setTab("supplier");
    }

    if (tab === "supplier") return;

    const currentTabIndex = steps.findIndex((s) => s.key === tab);

    console.log("currentTabIndex", currentTabIndex, tab);

    if (!steps[currentTabIndex - 1].done) {
      setTab(
        steps[currentTabIndex - 1].key as "supplier" | "products" | "overview",
      );
    }
  }, [tab, deliveryOrderId, existingOrder, isGetDeliveryOrderError]);

  const handleNextStep = () => {
    setSteps((prev) => {
      return prev.map((s) => {
        if (s.key === tab) {
          return { ...s, done: true };
        }
        return s;
      });
    });

    const currentTabIndex = steps.findIndex((s) => s.key === tab);

    setTab(
      steps[currentTabIndex + 1].key as "supplier" | "products" | "overview",
    );
  };

  const handleSubmitForm = async (
    values: z.infer<typeof manageDeliveryOrderSchema>,
  ) => {
    const payload = manageDeliveryOrderSchema.parse(values);

    // create new
    if (deliveryOrderId === "-1") {
      await postDeliveryOrder({
        ...payload,
        locationId: payload.locationId as number,
        supplier: payload.supplier as number,
        date: dayjs(payload.date).format("YYYY-MM-DD"),
      });

      notifications.show({
        message: t`Dobavnica je bila ustvarjena`,
        color: "green",
      });

      navigate("/dashboard/delivery-orders");
      return;
    }

    // update existing
    await updateExistingOrder({
      body: {
        ...payload,
        locationId: payload.locationId as number,
        supplier: payload.supplier as number,
        date: dayjs(payload.date).format("YYYY-MM-DD"),
      },
      deliveryOrderId: Number(deliveryOrderId),
    });

    notifications.show({
      message: t`Dobavnica je posodobljena`,
      color: "green",
    });

    navigate("/dashboard/delivery-orders");
    return;
  };

  const netPriceTotalWithoutTotalDiscount =
    Math.round(
      deliveryOrderForm
        .getValues()
        .products.reduce(
          (acc, p) =>
            acc + p.quantity * p.netPricePer * (1 - p.discountPercentage / 100),
          0,
        ) * 100,
    ) / 100;

  const grossPriceTotalWithoutTotalDiscount =
    Math.round(
      deliveryOrderForm
        .getValues()
        .products.reduce(
          (acc, p) =>
            acc +
            p.quantity * p.grossPricePer * (1 - p.discountPercentage / 100),
          0,
        ) * 100,
    ) / 100;

  const isEditing = deliveryOrderId != "-1";

  const subPage: LimePageHeaderProps["subPage"] = {
    title: isEditing ? t`Uredi dobavnico` : t`Ustvari dobavnico`,
    onBackButtonClick: () => navigate("/dashboard/delivery-orders"),

    rightSection: isEditing
      ? {
          options: [
            {
              label: t`Izbriši dobavnico`,
              color: Color.Error,
              icon: <VscTrash />,
              onClick: async () => {
                if (deliveryOrderId != null) {
                  alertModal({
                    title: t`Ali ste prepričani, da želite izbrisati dobavnico?`,
                    onConfirm: async () => {
                      await deleteExistingOrder({
                        keepQuantity: false,
                        deliveryOrderId: Number(deliveryOrderId),
                      });

                      notifications.show({
                        message: t`Dobavnica je bila izbrisana`,
                        color: "green",
                      });

                      navigate("/dashboard/delivery-orders");
                    },
                    isDanger: true,
                  });
                }
              },
            },
          ],
        }
      : undefined,
  };

  const disabledKeys = (() => {
    const keys: string[] = [];

    if (!steps.find((s) => s.key === "products")!.done) {
      keys.push("overview");
    }

    if (!steps.find((s) => s.key === "supplier")!.done) {
      keys.push("products");
    }

    return keys;
  })();

  return (
    <div className="flex flex-col items-center gap-4">
      <LimePageHeader title={""} subPage={subPage}></LimePageHeader>

      <Tabs
        color="primary"
        disabledKeys={disabledKeys}
        selectedKey={tab}
        fullWidth
        className="px-4 md:max-w-[450px]"
        onSelectionChange={(key) =>
          setTab(key as "supplier" | "products" | "overview")
        }
      >
        <Tab key="supplier" title={t`Dobavitelj`}></Tab>
        <Tab key="products" title={t`Artikli`}></Tab>
        <Tab key="overview" title={t`Pregled`}></Tab>
      </Tabs>
      {tab === "supplier" && (
        <SupplierTab
          form={deliveryOrderForm}
          handleNextStep={handleNextStep}
          fieldsToValidate={[
            "locationId",
            "number",
            "supplier",
            "date",
            "totalDiscountPercentage",
          ]}
        ></SupplierTab>
      )}
      {tab === "products" && (
        <ProductsTab
          form={deliveryOrderForm}
          handleNextStep={handleNextStep}
          grossPriceTotalWithoutTotalDiscount={
            grossPriceTotalWithoutTotalDiscount
          }
          netPriceTotalWithoutTotalDiscount={netPriceTotalWithoutTotalDiscount}
          fieldsToValidate={["products"]}
        ></ProductsTab>
      )}

      {tab === "overview" && (
        <OverviewTab
          form={deliveryOrderForm}
          handleSubmit={handleSubmitForm}
          grossPriceTotalWithoutTotalDiscount={
            grossPriceTotalWithoutTotalDiscount
          }
          netPriceTotalWithoutTotalDiscount={netPriceTotalWithoutTotalDiscount}
          isEditing={isEditing}
        ></OverviewTab>
      )}
    </div>
  );
};
