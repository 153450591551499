import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { api } from "@/lib/api-client";
import { i18n } from "@lingui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "lucide-react";
import { useDebouncedState } from "@mantine/hooks";

export const DeliveryOrders = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useDebouncedState("", 200);

  const { data: deliveryOrdersData, isFetching } =
    api.deliveryOrder.useGetDeliveryOrders({ search });

  const { locale } = i18n;

  return (
    <>
      <LimePageHeader
        title={t`Dobavnice`}
        rightSection={{
          label: t`Dodaj dobavnico`,
          onClick: () => navigate("/dashboard/delivery-orders/-1"),
          identifier: "add-delivery-order",
        }}
      ></LimePageHeader>

      <div className="flex flex-col gap-4 px-4 py-4 md:px-8">
        <LimeInput label={t`Iskanje`} onValueChange={setSearch}></LimeInput>
        <Table removeWrapper>
          <TableHeader>
            <TableColumn>
              <Trans>Številka dobavnice</Trans>
            </TableColumn>
            <TableColumn>
              <Trans>Znesek</Trans>
            </TableColumn>
            <TableColumn>
              <Trans>Datum</Trans>
            </TableColumn>
          </TableHeader>
          <TableBody>
            {deliveryOrdersData
              ? deliveryOrdersData.deliveryOrders.map((deliveryOrder) => (
                  <TableRow
                    key={deliveryOrder.id}
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(`/dashboard/delivery-orders/${deliveryOrder.id}`)
                    }
                  >
                    <TableCell>{deliveryOrder.orderNumber}</TableCell>
                    <TableCell>
                      {deliveryOrder.totalGrossPriceFormatted}
                    </TableCell>
                    <TableCell>
                      {dayjs(deliveryOrder.dateReceived)
                        .toDate()
                        .toLocaleString(locale, {
                          day: "numeric",
                          month: "numeric",
                          year: "2-digit",
                          hour: "numeric",
                          minute: "numeric",
                        })}
                    </TableCell>
                  </TableRow>
                ))
              : []}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
