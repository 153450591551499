import { t } from "@lingui/core/macro";
import { GetLocations, GetOrganizationInvoice } from "@/server-types";
import { PrintData } from "qz-tray";
import QRCode from "qrcode";
import { formatCurrency } from "../../../shared/utils/utils";
import { i18n } from "@lingui/core";

type PrinterConfig =
  GetLocations["response"]["locations"][number]["PosPrinter"];

interface IPrinterAdapter {
  adapt(renderedOutput: string[] | string): PrintData;
}

export class HtmlAdapter implements IPrinterAdapter {
  constructor(private config: PrinterConfig) {}

  adapt(htmlString: string[] | string): PrintData {
    const html = Array.isArray(htmlString) ? htmlString.join("") : htmlString;

    // Wrap all HTML output in a <div> that sets the root font size
    const fontSize = this.config?.htmlRootFontSize || 12; // default fallback
    const wrappedHtml = `<div style="font-size: ${fontSize}px; max-width: 100%;">${html}</div>`;

    const pageWidth = this.config?.lineWidth || 384;

    return {
      type: "pixel",
      format: "html",
      flavor: "plain",
      data: wrappedHtml,
      // options: {
      //   pageWidth,
      // },
    };
  }
}

/**
 * For generic ESC/POS, we return:
 *   {
 *     type: "raw",
 *     format: "command",
 *     flavor: "base64",
 *     data: base64EncodedString
 *   }
 *
 * If you have an array of ESC/POS commands, you'll typically join them
 * and then base64-encode. QZ Tray expects one base64-encoded string,
 * but you can also pass an array if you prefer.
 */
class EscposAdapter implements IPrinterAdapter {
  adapt(commands: string[] | string): PrintData {
    // If commands is an array, join it into a single string:
    const escposData = Array.isArray(commands) ? commands.join("") : commands;
    // Base64-encode the commands:
    const base64Data = btoa(escposData);

    return {
      type: "raw",
      format: "command",
      flavor: "base64",
      data: base64Data,
    };
  }
}

function createPrinterAdapter(config: PrinterConfig): IPrinterAdapter {
  switch (config?.printerType) {
    case "escpos_generic":
      // Both paths use the same ESC/POS approach (if that’s what you intend)
      return new EscposAdapter();
    case "star_mi":
      return new HtmlAdapter(config);
    default:
      throw new Error(`Unsupported printer type: ${config?.printerType}`);
  }
}

export async function preparePrintData(
  doc: PrintableDocument,
  config: PrinterConfig,
): Promise<PrintData> {
  if (!config) {
    throw new Error("Printer config is required");
  }

  const adapter = createPrinterAdapter(config);

  if (config.printerType === "escpos_generic") {
    const escPosCommands = await renderDocumentToEscPos(doc, config);
    // Convert commands to QZ Tray PrintData
    return adapter.adapt(escPosCommands);
  } else if (config.printerType === "star_mi") {
    const htmlString = await renderDocumentToHtml(doc, config);
    // Convert to QZ Tray PrintData
    return adapter.adapt(htmlString);
  }

  throw new Error(`Unsupported printer type: ${config.printerType}`);
}

export interface InvoiceDocument {
  type: "invoice";
  invoiceData: GetOrganizationInvoice["response"];
  isCopy: boolean;
}
interface GiftCardDocument {
  type: "gift-card";
  data: {
    operatorLabel: string;
    organization: {
      name: string;
      address: string;
      address2?: string;
      city: string;
      zip: string;
      taxNumber: string;
    };
    giftCard: {
      code: string;
      issueDate: string;
      expiryDate: string;
      initialAmountCents: number;
      initialAmountFormatted: string;
      amountLeftCents: number;
      amountLeftFormatted: string;
    };
    currencyId: string;
  };
}
interface GiftCardReceiptDocument {
  type: "gift-card-receipt";
  data: {
    operatorLabel: string;
    organization: {
      name: string;
      address: string;
      address2?: string;
      city: string;
      zip: string;
      taxNumber: string;
    };
    payments: Record<string, { amountCents: number; amountFormatted: string }>;
    issuedGiftCards: {
      initialAmountCents: number;
      initialAmountFormatted: string;
    }[];
    dateTime: string;
    location: string;
    currencyId: string;
  };
}
interface TransactionAccountReceiptDocument {
  type: "transaction-account";
  data: {
    invoiceNumber: string; // number
    amount: number; // totalDue
    currency: string; // currencyId
    amountFormatted: string; // totalDueFormatted
    name: string; // _documentIssuer.name
    address: string; // _documentIssuer.address
    zip: string; // _documentIssuer.zip
    city: string; // _documentIssuer.city
    country: string; // _documentIssuer.country
    IBAN: string; // _documentIssuer.IBAN
    SWIFT: string; // _documentIssuer.SWIFT
    reference: string; // reference
    dateDue: string; // dateDue
  };
}
export type PrintableDocument =
  | InvoiceDocument
  | GiftCardDocument
  | GiftCardReceiptDocument
  | TransactionAccountReceiptDocument;

export const renderDocumentToEscPos = async (
  doc: PrintableDocument,
  printerConfig: PrinterConfig,
): Promise<string[]> => {
  if (!printerConfig) {
    throw new Error("Printer config is required");
  }

  const rawCommands = await (async () => {
    switch (doc.type) {
      case "invoice":
        return renderInvoiceToEscPos({ ...doc, printerConfig });
      case "gift-card":
        return renderGiftCardToEscPos(doc);
      case "gift-card-receipt":
        return renderGiftCardReceiptToEscPos({ doc, printerConfig });
      case "transaction-account":
        return renderTransactionAccountReceiptToEscPos(doc);
    }
  })();

  const singleString = String.fromCharCode(...rawCommands);
  return [singleString];
};

export async function renderDocumentToHtml(
  doc: PrintableDocument,
  printerConfig: PrinterConfig,
): Promise<string> {
  switch (doc.type) {
    case "invoice":
      return await renderInvoiceToHtml({ ...doc, printerConfig });
    case "gift-card":
      return renderGiftCardToHtml(doc);
    case "gift-card-receipt":
      return renderGiftCardReceiptToHtml(doc);
    case "transaction-account":
      return await renderTransactionAccountReceiptToHtml(doc);
    // default:
    //   throw new Error(`Unsupported document type: ${doc}`);
  }
}

async function renderInvoiceToHtml({
  invoiceData: invoice,
  printerConfig,
  isCopy,
}: {
  invoiceData: GetOrganizationInvoice["response"];
  printerConfig: GetLocations["response"]["locations"][number]["PosPrinter"];
  isCopy: boolean;
}): Promise<string> {
  if (!printerConfig) {
    throw new Error("Printer config is required");
  }

  const companyData = invoice.additionalReceiptData._documentIssuer;
  const companyInfo = `
    <div style="text-align: center">
      <span>${companyData.name}</span>
      <br />
      ${
        companyData.address
          ? `
        <span>${companyData.address}</span>
        <br />
      `
          : ``
      }
      ${companyData.zip ? `<span>${companyData.zip} ${companyData.city}</span>` : ``}
      <br />
      <span>${companyData.isTaxSubject ? `ID za DDV` : `Davcna stevilka`}: ${companyData.taxNumber || "/"}</span>
      <br />
    </div>
  `;

  const customerData = invoice.additionalReceiptData.customerData;
  const customerInfo = customerData
    ? `
    <div style="text-align: center">
      <span>${customerData.name}</span>
      <br />
      ${
        customerData.address
          ? `
        <span>${customerData.address}</span>
        <br />
      `
          : ``
      }
      ${customerData.zip && customerData.city ? `<span>${customerData.zip} ${customerData.city}</span>` : ``}
      <br />
      <span>ID za DDV: ${customerData.taxNumber || "/"}</span>
      <br />
    </div>
  `
    : ``;

  const invoiceHeader = `
    <div style="font-weight: bold; text-align: center;">
      ${invoice.canceled ? "STORNIRAN RACUN" : ""}
      <br />
      RACUN ST.: ${invoice.number}
    </div>
  `;

  // 5. Items table
  //    Instead of spacing with ESC/POS commands, create a simple HTML table
  const itemsTableRows = invoice.additionalReceiptData.invoiceData.items
    .map((item) => {
      // First row: only the article name (spread across all columns)
      const row1 = `
      <tr>
        <td colspan="4" style="text-align: left;">${item.name}</td>
      </tr>
    `;

      // Second row: price, quantity, discount, amount
      const row2 = `
      <tr>
        <td style="text-align: right;">${item.price.toFixed(2)}</td>
        <td style="text-align: right;">${item.quantity}x</td>
        <td style="text-align: right;">${item.discount || ""}</td>
        <td style="text-align: right;">${item.totalWithTax.toFixed(2)}</td>
      </tr>
    `;

      return row1 + row2;
    })
    .join("");

  const itemsTable = `
  <table style="width: 100%; border-collapse: collapse; margin-top: 0.5em; border-bottom: 1px solid black;">
    <!-- Table header for the second row -->
    <thead style="font-weight: bold; border-bottom: 1px solid black;">
      <tr>
        <td>Artikel/Cena</td>
        <td>Kol</td>
        <td>Popust</td>
        <td>Vrednost</td>
      </tr>
    </thead>
    <tbody>
      ${itemsTableRows}
    </tbody>
  </table>
`;

  // 6. Totals section
  const totalsSection = `
    <div style="margin-top: 1em;">
      <div style="display: flex; justify-content: space-between;">
        <span>SKUPAJ:</span>
        <span>${invoice.totalWithoutDiscount}</span>
      </div>
      ${
        invoice.totalDiscount
          ? `<div style="display: flex; justify-content: space-between;">
              <span>POPUST:</span> 
              <span>-${invoice.totalDiscountFormatted}</span>
             </div>`
          : ``
      }
      <div style="display: flex; justify-content: space-between;">
        <span>ZA PLACILO ${invoice.currencyId}:</span>
        <span style="font-size: 1.2em; font-weight: bold;">
          ${invoice.total}
        </span>
      </div>
    </div>
  `;

  const paymentMethodAmountsHTML = `
  <table style="width: 100%; border-collapse: collapse;">
    <tbody>
      ${invoice.additionalReceiptData.payments
        .map((payment) => {
          const label = getPaymentTypeLabel(payment.type as PaymentType);
          const formattedAmount = payment.amount.toFixed(2);

          return `
            <tr>
              <td style="text-align: left;">${label}</td>
              <td style="text-align: right;">${formattedAmount}</td>
            </tr>
          `;
        })
        .join("")}
    </tbody>
  </table>
`;

  let totalNetPrice = 0;
  let totalTaxAmount = 0;
  let totalGrossPrice = 0;

  const invoiceTaxesArray =
    invoice.additionalReceiptData.invoiceData.invoiceTaxes.map((tax) => {
      const netPrice = parseFloat(tax.base);
      const taxAmount = parseFloat(tax.totalTaxAmount);
      const grossPrice = netPrice + taxAmount;

      // Accumulate totals
      totalNetPrice += netPrice;
      totalTaxAmount += taxAmount;
      totalGrossPrice += grossPrice;

      return {
        taxRate: tax.taxRate.toFixed(1), // e.g. "22.0"
        netPrice: netPrice.toFixed(2),
        taxAmount: taxAmount.toFixed(2),
        grossPrice: grossPrice.toFixed(2),
      };
    });

  // Build the HTML table for taxes:
  function buildTaxHtmlTable(
    invoiceTaxes: {
      taxRate: string; // e.g. "22.0"
      netPrice: string; // e.g. "100.00"
      taxAmount: string; // e.g. "22.00"
      grossPrice: string; // e.g. "122.00"
    }[],
    totalNetPrice: number,
    totalTaxAmount: number,
    totalGrossPrice: number,
  ): string {
    // Map each tax line to a <tr> with right-aligned columns
    const taxLines = invoiceTaxes
      .map((t) => {
        return `
        <tr>
          <td style="text-align: right;">${t.taxRate}</td>
          <td style="text-align: right;">${t.netPrice}</td>
          <td style="text-align: right;">${t.taxAmount}</td>
          <td style="text-align: right;">${t.grossPrice}</td>
        </tr>
      `;
      })
      .join("");

    // Totals row
    const totalsRow = `
    <tr style="font-weight: bold; border-top: 1px solid #ccc;">
      <td style="text-align: left;">SKUPAJ:</td>
      <td style="text-align: right;">${totalNetPrice.toFixed(2)}</td>
      <td style="text-align: right;">${totalTaxAmount.toFixed(2)}</td>
      <td style="text-align: right;">${totalGrossPrice.toFixed(2)}</td>
    </tr>
  `;

    // Put it all in one table
    return `
    <table style="width: 100%; border-collapse: collapse; margin-top: 1em;">
      <thead style="border-bottom: 1px solid #ccc;">
        <tr>
          <th style="text-align: right;">DDV %</th>
          <th style="text-align: right;">Neto</th>
          <th style="text-align: right;">DDV</th>
          <th style="text-align: right;">Bruto</th>
        </tr>
      </thead>
      <tbody>
        ${taxLines}
        ${totalsRow}
      </tbody>
    </table>
  `;
  }

  const taxTableHtml = buildTaxHtmlTable(
    invoiceTaxesArray,
    totalNetPrice,
    totalTaxAmount,
    totalGrossPrice,
  );

  // 7. Taxes & Additional Info
  const taxInfo = `
    <div style="margin-top: 1em;">
      ${taxTableHtml}

      <br />

      <p style="font-size: 0.85em;">${invoice.location.city ? invoice.location.city + ", " : ""}${invoice.date}</p>
      ${
        invoice.additionalReceiptData.employeeData?.name
          ? `<p style="font-size: 0.85em;">Racun izdal: ${invoice.additionalReceiptData.employeeData.name}</p>`
          : ``
      }
      ${
        !companyData.isTaxSubject
          ? `<br />
            <p style="font-size: 0.85em; text-align: center;">
              DDV ni obracunan na podlagi 1. odstavka 94. clena ZDDV-1.
            </p>`
          : ``
      }
          
      <br/>

      <p>ZOI: ${invoice.additionalReceiptData.ZOI || ""}</p>
      <p>EOR: ${invoice.additionalReceiptData.EOR || ""}</p>
    </div>
  `;

  // 8. Thank-you message & optional QR
  const qrCode = invoice.additionalReceiptData?.QR
    ? await buildHtmlQrCode(invoice.additionalReceiptData.QR)
    : ``;
  // const qrCode = ``;

  const thanksMessage = `
    <div style="text-align: center; margin-top: 1em;">
      ${qrCode}
      <div>Hvala za obisk!</div>
      <div>www.lime-booking.si</div>
    </div>
  `;

  const printCount = invoice.additionalReceiptData.printCount + 1;

  return `
    <div style="width: 100%;">
      <style>
          :root {
            font-family: Arial;
          }

          p {
            margin: 0;
            font-size: 1em;
          }

          table, td {
            font-size: 1em;
          }

          span {
            font-size: 0.85em;
          }
        </style>

      <div style="width: 100%;">
        ${companyInfo}
        <br />
        ${customerInfo}
        <br />
        ${isCopy ? `<p style="text-align: center;">Kopija ${printCount - 1}</p>` : ``}
        <br />
        ${invoiceHeader}
        <br />
        ${itemsTable}
        <br />
        ${totalsSection}
        ${paymentMethodAmountsHTML}
        <br />
        ${taxInfo}
        <br />
        ${thanksMessage}
      </div>
    </div>
  `;
}

function renderGiftCardToHtml(giftCardData: GiftCardDocument): string {
  const { organization, giftCard, operatorLabel } = giftCardData.data;

  return `
    <div style="text-align: center;">
      <style>
        p {
          margin: 0;
          font-size: 1em;
          font-family: Arial;
        }
        span {
          font-size: 0.85em;
          font-family: Arial;
        }
      </style>
      <p>${organization.name}</p>
      <p>${organization.address}${organization.address2 || ""}, ${organization.zip} ${organization.city}</p>
      <p>${organization.taxNumber}</p>
      <br />
      <div style="text-align: left;">
        <p>Blagajnik: ${operatorLabel}</p>
      </div>
      <br/>
      <div style="text-align: center; font-size: 1.75em;">
        <span>DARILNI BON</span>
        <span>${giftCard.initialAmountFormatted}</span>
      </div>
      ${
        giftCard.amountLeftCents !== giftCard.initialAmountCents
          ? `
            <p>Preostali znesek: ${giftCard.amountLeftFormatted}</p>
        `
          : ``
      }
      <br/>

      <p style="font-weight: bold;">Koda<p>
      <p style="font-weight: bold;">${giftCard.code}</p>

      <br/>

      <div style="text-align: left;">
        <p>Veljavnost od: ${giftCard.issueDate}</p>
        <p>Veljavnost do: ${giftCard.expiryDate}</p>
      </div>
      <br />
      <span style="margin-bottom: 0;">${t`Hvala za obisk!`}</span>
      <br />
      <span style="margin-top: 0;">www.lime-booking.si</span>
    </div>
  `;
}

function renderGiftCardReceiptToHtml(
  receiptData: GiftCardReceiptDocument,
): string {
  const {
    organization,
    issuedGiftCards,
    operatorLabel,
    payments,
    dateTime,
    location,
  } = receiptData.data;

  return `
    <div style="text-align: center;">
      <style>
        p {
          margin: 0;
          font-size: 1em;
          font-family: Arial;
        }
        span {
          font-size: 0.85em;
          font-family: Arial;
        }
      </style>
      <p>${organization.name}</p>
      <p>${organization.address}${organization.address2 || ""}, ${organization.zip} ${organization.city}</p>
      <p>${organization.taxNumber}</p>
      <br />
      <div style="text-align: left;">
        <p>Blagajnik: ${operatorLabel}</p>
      </div>
      <br/>

      <div style="text-align: left;">
        <p style="border-bottom: 1px solid black;">Prejeta plačilna sredstva</p>
        ${Object.entries(payments)
          .map(
            ([type, data]) => `
          <div style="display: flex; justify-content: space-between;">
            <span>${getPaymentTypeLabel(type as PaymentType)}</span>
            <span>${data.amountFormatted}</span>  
          </div>
        `,
          )
          .join("")}

        <br />

        <p style="border-bottom: 1px solid black;">Izdana plačilna sredstva</p>
        ${issuedGiftCards
          .map(
            (g) => `<div style="display: flex; justify-content: space-between;">
                    <span>DARILNI BON</span>
                    <span>${g.initialAmountFormatted}</span>  
                  </div>`,
          )
          .join("")}
      </div>

      <br/>
      <br/>

      <p>${location}, ${dateTime}</p>

      <br/>
      <br/>

      <span style="margin-bottom: 0;">${t`Hvala za obisk!`}</span>
      <br />
      <span style="margin-top: 0;">www.lime-booking.si</span>
    </div>
  `;
}

async function renderTransactionAccountReceiptToHtml(
  doc: TransactionAccountReceiptDocument,
): Promise<string> {
  const { data } = doc;

  const purpose = data.invoiceNumber;
  const htmlQrCode = await buildHtmlQrCode(
    buildUpnQrCodeString({
      invoiceNumber: data.invoiceNumber,
      amount: data.amount,
      dueDate: data.dateDue,
      paymentPurpose: purpose,
      purposeCode: "OTHR",
      recipientCity: data.city,
      recipientIban: data.IBAN,
      recipientName: data.name,
      recipientReference: data.reference,
      recipientStreet: data.address,
      payerCity: "",
      payerName: "",
      payerStreet: "",
    }),
    "80%",
  );

  return `
    <div style="text-align: center;">
      <style>
        p {
          margin: 0;
          font-size: 1em;
          font-family: Arial;
        }

        span {
          font-size: 0.85em;
          font-family: Arial;
        }
      </style>
      <p>${t`Racun`}: ${data.invoiceNumber}</p>
      <p>${t`Znesek`}: ${data.amount.toFixed(2)} ${data.currency}</p>
      <p>${t`Ime`}: ${data.name}</p>
      <p>${t`Naslov`}: ${data.address} ${data.city} ${data.country}</p>
      <p>IBAN: ${data.IBAN}</p>
      <p>SWIFT: ${data.SWIFT}</p>
      <p>${t`Referenca`}: ${data.reference}</p>
      <p>${t`Namen`}: ${purpose}</p>
      <p>${t`Rok placila`}: ${data.dateDue}</p>

      <br />

      ${htmlQrCode}

      <br />
      <span style="margin-bottom: 0;">${t`Hvala za obisk!`}</span>
      <br />
      <span style="margin-top: 0;">www.lime-booking.si</span>
    </div>
  `;
}

const renderInvoiceToEscPos = ({
  invoiceData: invoice,
  printerConfig,
  isCopy,
}: {
  invoiceData: GetOrganizationInvoice["response"];
  printerConfig: PrinterConfig;
  isCopy: boolean;
}) => {
  if (!printerConfig) {
    throw new Error("Printer config is required");
  }

  const encoder = new TextEncoder();

  const lineWidth = printerConfig.lineWidth;

  const itemNameMaxLength = lineWidth; // Entire line width for item name
  const itemPriceMaxLength = 12; // Length for price (Cena)
  const itemQuantityMaxLength = 4; // Length for quantity (Kol)
  const itemDiscountMaxLength = 7; // Length for discount (Popust)
  const itemTotalMaxLength = 9; // Length for total value (Vrednost)

  const taxRateMaxLength = 7; // Length for tax rate
  const taxNetPriceMaxLength = 9; // Length for tax net price
  const taxTotalMaxLength = 7; // Length for tax gross price
  const taxGrossPriceMaxLength = 9; // Length for tax gross price

  // Format a single item into two lines
  const formatItemLines = (
    name: string,
    price: string,
    quantity: number,
    discount: string,
    total: string,
  ) => {
    const namePadded = name
      .substring(0, itemNameMaxLength - 1)
      .padEnd(itemNameMaxLength - 1); // Ensure the name is left-aligned
    const pricePadded = price.padStart(itemPriceMaxLength);
    const quantityPadded = `${quantity}x`.padStart(itemQuantityMaxLength);
    const discountPadded = (discount || "").padStart(itemDiscountMaxLength);
    const totalPadded = total.padStart(itemTotalMaxLength);
    const secondLine = `${pricePadded}${quantityPadded}${discountPadded}${totalPadded}\n`;
    return `${namePadded}\n${secondLine}`;
  };

  const itemsArray = invoice.additionalReceiptData.invoiceData.items.map(
    (item) =>
      encoder.encode(
        formatItemLines(
          item.name, // Item name
          item.price.toFixed(2), // Cena
          item.quantity, // Kol
          item.discount ?? "", // Popust (empty string if not provided)
          item.totalWithTax.toFixed(2), // Vrednost
        ),
      ),
  );

  const flattenedItemsArray = itemsArray.reduce((acc, val) => {
    const newArray = new Uint8Array(acc.length + val.length);
    newArray.set(acc);
    newArray.set(val, acc.length);
    return newArray;
  }, new Uint8Array());

  // Format the tax details into a properly aligned string
  const formatTaxLine = (
    taxRate: string,
    netPrice: string,
    taxTotal: string,
    grossPrice: string,
  ): string => {
    const taxRatePadded = taxRate.padStart(taxRateMaxLength);
    const netPricePadded = netPrice.padStart(taxNetPriceMaxLength);
    const taxTotalPadded = taxTotal.padStart(taxTotalMaxLength);
    const grossPricePadded = grossPrice.padStart(taxGrossPriceMaxLength);
    return `${taxRatePadded}${netPricePadded}${taxTotalPadded}${grossPricePadded}\n`;
  };

  let totalNetPrice = 0;
  let totalTaxAmount = 0;
  let totalGrossPrice = 0;

  const invoiceTaxesArray =
    invoice.additionalReceiptData.invoiceData.invoiceTaxes.map((tax) => {
      const netPrice = parseFloat(tax.base);
      const taxAmount = parseFloat(tax.totalTaxAmount);
      const grossPrice = netPrice + taxAmount;

      // Accumulate totals
      totalNetPrice += netPrice;
      totalTaxAmount += taxAmount;
      totalGrossPrice += grossPrice;

      return encoder.encode(
        formatTaxLine(
          tax.taxRate.toFixed(1), // DDV %
          netPrice.toFixed(2), // Neto
          taxAmount.toFixed(2), // DDV
          grossPrice.toFixed(2), // Bruto
        ),
      );
    });

  const totalsLine = encoder.encode(
    formatTaxLine(
      "SKUPAJ:", // No tax rate for totals
      totalNetPrice.toFixed(2), // Total Neto
      totalTaxAmount.toFixed(2), // Total DDV
      totalGrossPrice.toFixed(2), // Total Bruto
    ),
  );

  const flattenedInvoiceTaxesArray = invoiceTaxesArray.reduce((acc, val) => {
    const newArray = new Uint8Array(acc.length + val.length);
    newArray.set(acc);
    newArray.set(val, acc.length);
    return newArray;
  }, new Uint8Array());

  // Encode a divider line
  const divider = encoder.encode("-".repeat(lineWidth - 1) + "\n");

  // Concatenate the divider and totals line to the flattened array
  const taxesWithDividerAndTotals = new Uint8Array(
    flattenedInvoiceTaxesArray.length + divider.length + totalsLine.length,
  );
  taxesWithDividerAndTotals.set(flattenedInvoiceTaxesArray);
  taxesWithDividerAndTotals.set(divider, flattenedInvoiceTaxesArray.length);
  taxesWithDividerAndTotals.set(
    totalsLine,
    flattenedInvoiceTaxesArray.length + divider.length,
  );

  const rawQrCodeData = buildEscPosQrCode(
    encoder.encode(invoice.additionalReceiptData.QR),
  );
  const companyData = invoice.additionalReceiptData._documentIssuer;
  const customerData = invoice.additionalReceiptData.customerData;

  const companyPrintCommands =
    companyData != null
      ? [
          ...encoder.encode(`${companyData.name}\n`),
          ...(companyData.address
            ? encoder.encode(`${companyData.address}\n`)
            : []),
          ...(companyData.zip
            ? encoder.encode(`${companyData.zip} ${companyData.city}\n`)
            : []),
          ...encoder.encode(
            `${companyData.isTaxSubject ? "ID za DDV" : "Davcna stevilka"}: ${companyData.taxNumber || "/"}\n`,
          ),
        ]
      : [];

  const customerPrintCommands =
    customerData != null
      ? [
          ...encoder.encode(`\n\n${customerData.name}\n`),
          ...(customerData.address
            ? encoder.encode(`${customerData.address}\n`)
            : []),
          ...(customerData.zip && customerData.city
            ? encoder.encode(`${customerData.zip} ${customerData.city}\n`)
            : []),
          ...encoder.encode(`ID za DDV: ${customerData.taxNumber || "/"}\n`),
        ]
      : [];

  const paymentMethodAmountsCommands =
    invoice.additionalReceiptData.payments.map((payment) => {
      const typePadded = getPaymentTypeLabel(
        payment.type as PaymentType,
      ).padEnd(lineWidth - 10); // Adjust padding based on your needs
      const amountPadded = payment.amount.toFixed(2).padStart(9); // Right align the amount
      return encoder.encode(`${typePadded}${amountPadded}\n`);
    });

  const flattenedPaymentMethodAmountsArray =
    paymentMethodAmountsCommands.reduce((acc, val) => {
      const newArray = new Uint8Array(acc.length + val.length);
      newArray.set(acc);
      newArray.set(val, acc.length);
      return newArray;
    }, new Uint8Array());

  const printCount = invoice.additionalReceiptData.printCount + 1;

  const commands = [
    0x0a, // New line
    0x0a, // New line

    // Print company info in normal size
    0x1b,
    0x00, // Normal size
    ...companyPrintCommands,

    // Print customer info if exists
    ...customerPrintCommands,

    0x0a, // New line
    0x0a, // New line

    // Print copy if isCopy
    ...(isCopy && printCount > 1
      ? encoder.encode(`Kopija ${printCount - 1}\n\n\n`)
      : []),

    // Print RACUN ST. in bold and normal text size
    0x1b,
    0x45,
    0x01, // Turn on bold (ESC E 1)
    ...(invoice.canceled ? encoder.encode("STORNIRAN RACUN\n") : []),
    ...encoder.encode(`RACUN ST.: ${invoice.number}\n`),
    0x1b,
    0x45,
    0x00, // Turn off bold (ESC E 0)

    // turn off center alignment
    0x1b,
    0x61,
    0x00,

    0x0a, // New line

    // Print table header with fixed spacing
    ...encoder.encode("Artikel/Cena Kol Popust Vrednost\n"),
    0x1b,
    0x45,
    0x01, // Turn on bold (ESC E 1)
    ...encoder.encode("-".repeat(lineWidth - 1) + "\n"),
    0x1b,
    0x45,
    0x00, // Turn off bold (ESC E 0)

    ...flattenedItemsArray,

    0x1b,
    0x45,
    0x01, // Turn on bold (ESC E 1)
    ...encoder.encode("-".repeat(lineWidth - 1) + "\n"),
    0x1b,
    0x45,
    0x00, // Turn off bold (ESC E 0)

    // Print total
    ...encoder.encode(
      `SKUPAJ: ${invoice.totalWithoutDiscount.padStart(lineWidth - 9)}\n`,
    ),
    ...(invoice.totalDiscount != 0
      ? encoder.encode(
          `POPUST: ${`-${invoice.totalDiscountFormatted}`.padStart(lineWidth - 9)}\n`,
        )
      : []),
    // Print "ZA PLACILO" in normal size
    // Reset alignment to left
    0x1b,
    0x61,
    0x00, // Left alignment

    // Print "ZA PLACILO" in normal size
    ...encoder.encode(`ZA PLACILO ${invoice.currencyId.padEnd(5)}`),

    // Switch to larger text size for the total amount on the same line
    // 0x1b,
    // 0x21,
    // 0x30, // Double height and double width (ESC ! 0x30)
    ...encoder.encode(invoice.total.padStart(lineWidth - 17)),

    // Reset to normal text size
    0x1b,
    0x21,
    0x00, // Reset to normal text size

    0x0a, // New line
    0x0a, // New line

    ...flattenedPaymentMethodAmountsArray,

    0x0a, // New line

    // Print tax details
    ...encoder.encode(
      `--DDV %-----Neto----DDV----Bruto${"-".repeat(lineWidth - 33)}\n`,
    ),
    ...taxesWithDividerAndTotals,
    0x0a, // New line

    // Print seller info in a smaller size
    0x1b,
    0x21,
    0x01, // Slightly smaller text size
    // Print date and time
    ...encoder.encode(
      `${invoice.location.city ? invoice.location.city + ", " : ""}${invoice.date}\n`,
    ),
    ...(invoice.additionalReceiptData.employeeData?.name
      ? encoder.encode(
          `Racun izdal: ${invoice.additionalReceiptData.employeeData.name}\n`,
        )
      : []),
    0x1b,
    0x21,
    0x00, // Reset to normal text size

    0x0a, // New line

    // Print ZOI and EOR
    ...(invoice.additionalReceiptData.ZOI
      ? encoder.encode(`ZOI: ${invoice.additionalReceiptData.ZOI}\n`)
      : []),
    ...(invoice.additionalReceiptData.EOR
      ? encoder.encode(`EOR: ${invoice.additionalReceiptData.EOR}\n`)
      : []),
    0x0a, // New line

    ...rawQrCodeData,

    ...(companyData.isTaxSubject
      ? []
      : [
          0x01, // Slightly smaller text size
          ...encoder.encode(
            "DDV ni obracunan na podlagi 1. odstavka 94. clena ZDDV-1.",
          ),
          0x00, // Reset to normal text size
          0x0a,
          0x0a,
        ]),

    // Print centered www.lime-booking.si website url
    // Center alignment for the website URL
    0x1b,
    0x61,
    0x01, // Center alignment (ESC a 1)
    ...encoder.encode("Hvala za obisk!"),
    0x0a,
    ...encoder.encode("www.lime-booking.si"),
    0x0a,

    // Add some space at the bottom and cut paper if supported
    0x0a,
    0x0a,
  ];

  return commands;
};

const renderGiftCardToEscPos = (doc: GiftCardDocument) => {
  const encoder = new TextEncoder();

  const { organization, giftCard, operatorLabel, currencyId } = doc.data;

  const { locale } = i18n;

  const initialAmountFormatted = formatCurrency({
    amount: giftCard.initialAmountCents / 100,
    currency: currencyId,
    locale,
    options: {
      currencyDisplay: "code",
    },
    shouldSanitize: true,
  });

  console.log("initial amount", initialAmountFormatted);

  const amountLeftFormatted = formatCurrency({
    amount: giftCard.amountLeftCents / 100,
    currency: currencyId,
    locale,
    options: {
      currencyDisplay: "code",
    },
    shouldSanitize: true,
  });

  return [
    0x0a,
    0x0a,

    0x1b,
    0x61,
    0x01,
    0x00, // Normal size

    ...encoder.encode(`${organization.name}\n`),
    ...encoder.encode(
      `${organization.address}${organization.address2 || ""}, ${organization.zip} ${organization.city}\n`,
    ),
    ...encoder.encode(`${organization.taxNumber}\n`),

    0x0a,

    ...encoder.encode(`Blagajnik: ${operatorLabel}\n`),

    0x0a,

    ...encoder.encode(`DARILNI BON\n`),
    ...encoder.encode(`${initialAmountFormatted}\n`),

    ...(giftCard.initialAmountCents !== giftCard.amountLeftCents
      ? [
          ...encoder.encode(`Preostali znesek\n`),
          ...encoder.encode(`${amountLeftFormatted}\n`),
        ]
      : []),

    0x0a,

    0x1b,
    0x45,
    0x01, // Bold on
    ...encoder.encode(`KODA:\n`),
    ...encoder.encode(`${giftCard.code}\n`),
    0x1b,
    0x45,
    0x00, // Bold off

    0x0a,

    ...encoder.encode(`Veljavnost od: ${giftCard.issueDate}\n`),
    ...encoder.encode(`Veljavnost do: ${giftCard.expiryDate}\n`),

    0x0a,

    ...encoder.encode(`Hvala za obisk!\n`),

    0x0a,

    ...encoder.encode(`www.lime-booking.si\n`),

    0x0a, // New line
    0x0a, // New line
    0x0a, // New line
    0x0a, // New line
    0x0a, // New line
  ];
};

const renderGiftCardReceiptToEscPos = ({
  doc,
  printerConfig,
}: {
  doc: GiftCardReceiptDocument;
  printerConfig: PrinterConfig;
}) => {
  if (!printerConfig) {
    throw new Error("Printer config is required");
  }

  const encoder = new TextEncoder();

  const { locale } = i18n;

  const {
    organization,
    operatorLabel,
    payments,
    issuedGiftCards,
    currencyId,
    dateTime,
    location,
  } = doc.data;

  const lineWidth = printerConfig.lineWidth;

  const receivedPaymentsArray = Object.entries(payments).flatMap(
    ([type, data]) => {
      const paymentTypeLabel = getPaymentTypeLabel(type as PaymentType);

      const amountFormatted = formatCurrency({
        amount: data.amountCents / 100,
        currency: currencyId,
        locale,
        options: {
          currencyDisplay: "code",
        },
        shouldSanitize: true,
      });
      return [
        encoder.encode(
          `${paymentTypeLabel}: ${amountFormatted.padStart(lineWidth - paymentTypeLabel.length - 3)}\n`,
        ),
      ];
    },
  );
  const flattenedReceivedPaymentsArray = receivedPaymentsArray.reduce(
    (acc, val) => {
      const newArray = new Uint8Array(acc.length + val.length);
      newArray.set(acc);
      newArray.set(val, acc.length);
      return newArray;
    },
    new Uint8Array(),
  );

  const issuedGiftCardsArray = issuedGiftCards.map((igc) => {
    const initialAmountFormatted = formatCurrency({
      amount: igc.initialAmountCents / 100,
      currency: currencyId,
      locale,
      options: {
        currencyDisplay: "code",
      },
      shouldSanitize: true,
    });

    return encoder.encode(
      `DARILNI BON${initialAmountFormatted.padStart(lineWidth - 12)}\n`,
    );
  });
  const flattenedIssuedGiftCardsArray = issuedGiftCardsArray.reduce(
    (acc, val) => {
      const newArray = new Uint8Array(acc.length + val.length);
      newArray.set(acc);
      newArray.set(val, acc.length);
      return newArray;
    },
    new Uint8Array(),
  );

  return [
    // A couple of blank lines at the start
    0x0a,
    0x0a,

    // Center alignment: ESC a 1
    0x1b,
    0x61,
    0x01,
    0x00, // Normal text size (optional)

    // Organization info
    ...encoder.encode(`${organization.name}\n`),
    ...encoder.encode(
      `${organization.address}${organization.address2 || ""}, ${organization.zip} ${organization.city}\n`,
    ),
    ...encoder.encode(`${organization.taxNumber}\n`),

    0x0a, // blank line

    // Left alignment for "Blagajnik"
    0x1b,
    0x61,
    0x00,
    ...encoder.encode(`Blagajnik: ${operatorLabel}\n`),

    0x0a,

    // "Prejeta plačilna sredstva" (payments) in normal size
    ...encoder.encode(`Prejeta placilna sredstva\n`),
    ...encoder.encode("-".repeat(lineWidth - 1) + "\n"),

    // Print each payment line
    ...flattenedReceivedPaymentsArray,

    0x0a, // blank line

    // "Izdana plačilna sredstva" (issued gift cards)
    ...encoder.encode(`Izdana placilna sredstva\n`),
    ...encoder.encode("-".repeat(lineWidth - 1) + "\n"),

    // Print each gift card
    // ...issuedGiftCards.flatMap((g) => [
    //   ...encoder.encode(`DARILNI BON: ${g.amountFormatted}\n`),
    // ]),
    ...flattenedIssuedGiftCardsArray,

    0x0a, // blank line
    0x0a, // blank line

    ...encoder.encode(`${location}, ${dateTime}\n`),

    0x0a, // blank line
    0x0a, // blank line

    // Center alignment again for "Hvala za obisk!"
    0x1b,
    0x61,
    0x01,
    ...encoder.encode(`Hvala za obisk!\n`),
    ...encoder.encode(`www.lime-booking.si\n`),

    // Some extra new lines at the bottom
    0x0a,
    0x0a,
    0x0a,
    0x0a,
  ];
};

const renderTransactionAccountReceiptToEscPos = (
  doc: TransactionAccountReceiptDocument,
) => {
  const encoder = new TextEncoder();

  const { data } = doc;
  const purpose = data.invoiceNumber;

  return [
    0x0a,
    0x0a,

    0x1b,
    0x61,
    0x01,
    0x00, // Normal size

    ...encoder.encode(`Racun: ${data.invoiceNumber}\n`),
    ...encoder.encode(`Znesek: ${data.amountFormatted}\n`),
    ...encoder.encode(`Ime: ${data.name}\n`),
    ...encoder.encode(`Naslov: ${data.address} ${data.city} ${data.country}\n`),
    ...encoder.encode(`IBAN: ${data.IBAN}\n`),
    ...encoder.encode(`SWIFT: ${data.SWIFT}\n`),
    ...encoder.encode(`Referenca: ${data.reference}\n`),
    ...encoder.encode(`Namen: ${purpose}\n`),
    ...encoder.encode(`Rok placila: ${data.dateDue}\n`),

    ...buildEscPosQrCode(
      encoder.encode(
        buildUpnQrCodeString({
          invoiceNumber: data.invoiceNumber,
          amount: data.amount,
          dueDate: data.dateDue,
          paymentPurpose: purpose,
          purposeCode: "OTHR",
          recipientCity: data.city,
          recipientIban: data.IBAN,
          recipientName: data.name,
          recipientReference: data.reference,
          recipientStreet: data.address,
          payerCity: "",
          payerName: "",
          payerStreet: "",
        }),
      ),
    ),

    0x0a, // New line
    0x0a, // New line
  ];
};

const buildHtmlQrCode = async (rawQrCodeData?: string, width?: string) => {
  // Generate QR Code as a Base64 Data URL (PNG image)
  const qrDataUrl = await QRCode.toDataURL(rawQrCodeData || "", {
    width: 10,
  });

  return `<img src="${qrDataUrl}" style="${width ? `width: ${width}` : "width: 50%"}; height: auto;" />`;
};

function amountToUPNString(inputAmount: number) {
  // Convert "4,1" to "4.1" if needed
  const normalized = inputAmount.toString().replace(",", ".");

  // Parse as float
  const amount = parseFloat(normalized);

  // Convert to cents and round
  const amountCents = Math.round(amount * 100);

  // Convert to an 11-digit zero-padded string
  return amountCents.toString().padStart(11, "0");
}

const buildUpnQrCodeString = ({
  invoiceNumber,
  payerName,
  payerStreet,
  payerCity,
  amount,
  purposeCode,
  paymentPurpose,
  dueDate, // "dd.MM.yyyy" or ""
  recipientIban,
  recipientReference,
  recipientName,
  recipientStreet,
  recipientCity,
}: {
  invoiceNumber: string;
  payerName: string;
  payerStreet: string;
  payerCity: string;
  amount: number; // The payment amount in EUR
  purposeCode: string; // A four-letter code, e.g. "RENT"
  paymentPurpose: string; // A textual description of the payment
  dueDate: string; // "dd.MM.yyyy" format or an empty string if not used
  recipientIban: string; // The recipient's IBAN (no spaces)
  recipientReference: string; // The recipient's reference (e.g. "SI12...")
  recipientName: string; // The recipient's name
  recipientStreet: string; // The recipient's street and number
  recipientCity: string; // The recipient's city
}) => {
  const leadingStyle = "UPNQR"; // 1
  const payerIban = ""; // 2
  const polog = ""; // 3
  const dvig = ""; // 4
  const payerReference = invoiceNumber; // 5

  // Convert amount to an 11-digit string without decimal point.
  // For example, 81.05 => "0000008105".
  const amountStr = amountToUPNString(amount);

  console.log("AMOUNT STRING", amountStr);

  const paymentDate = ""; // 10 (often empty)
  const urgent = ""; // 11
  const paymentPurposeCode = purposeCode; // 12 (e.g. "RENT")
  const paymentPurposeField = paymentPurpose; // 13
  const paymentDueDate = dueDate; // 14 (e.g. "01.04.2017" or "")

  // recipientIban and recipientReference should be given without spaces
  // and already in correct format.

  const controlSum = "000"; // 20 (initially "000")
  const reserve = ""; // 21

  const fields = [
    leadingStyle, // 1
    payerIban, // 2
    polog, // 3
    dvig, // 4
    payerReference, // 5
    payerName, // 6
    payerStreet, // 7
    payerCity, // 8
    amountStr, // 9
    paymentDate, // 10
    urgent, // 11
    paymentPurposeCode, // 12
    paymentPurposeField, // 13
    paymentDueDate, // 14
    recipientIban, // 15
    recipientReference, // 16
    recipientName, // 17
    recipientStreet, // 18
    recipientCity, // 19
    controlSum, // 20 - placeholder
    reserve, // 21
  ];

  // Compute the control sum:
  // Join all fields with newline
  const dataString = fields.join("\n");

  // Calculate sum of ASCII values
  let sum = 0;
  for (let i = 0; i < dataString.length; i++) {
    sum += dataString.charCodeAt(i);
  }

  // Modulo 1000
  const computed = (sum % 1000).toString().padStart(3, "0");

  // Replace control sum field with the computed value
  fields[19] = computed;

  return fields.join("\n");
};

// https://en.wikipedia.org/wiki/EPC_QR_code
const buildEpcQrCodeString = ({
  BIC,
  name,
  IBAN,
  amount,
  currencyId,
  reason,
  invoiceNumber,
  reference,
}: {
  BIC: string;
  name: string;
  IBAN: string;
  amount: number;
  currencyId: string;
  reason: string;
  invoiceNumber: string;
  reference?: string;
}) => {
  const SERVICE_TAG = "BCD";
  const VERSION = "002";
  const CHAR_SET = 1;
  const ID_CODE = "SCT";

  const AMOUNT = `${currencyId}${amount.toFixed(2)}`;
  const PURPOSE_CODE = "OTHR";
  const STRUCTURED_REFERENCE = "";
  const UNSTRUCTURED_REFERENCE = reference || invoiceNumber || reason;
  const INFORMATION = "TEST EPC QR CODE";

  return [
    SERVICE_TAG,
    VERSION,
    CHAR_SET,
    ID_CODE,
    BIC,
    name,
    electronicFormat(IBAN),
    AMOUNT,
    PURPOSE_CODE,
    STRUCTURED_REFERENCE,
    UNSTRUCTURED_REFERENCE,
    INFORMATION,
  ].join("\n");
};

function electronicFormat(iban: string) {
  return iban.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
}

const buildEscPosQrCode = (encodedData?: Uint8Array<ArrayBufferLike>) => {
  if (!encodedData) return [];

  const qrDataLength = encodedData.length + 3;
  return Uint8Array.from([
    // Center alignment
    0x1b,
    0x61,
    0x01,

    // Set QR code model
    0x1d,
    0x28,
    0x6b,
    0x04,
    0x00,
    0x31,
    0x41,
    0x32,
    0x00, // Model 2 (standard)

    // Set QR code size (increase to make it bigger)
    0x1d,
    0x28,
    0x6b,
    0x03,
    0x00,
    0x31,
    0x43,
    0x06, // Size 6

    // Set QR code error correction level (49 = M)
    0x1d,
    0x28,
    0x6b,
    0x03,
    0x00,
    0x31,
    0x45,
    0x31,

    // Store QR code data
    0x1d,
    0x28,
    0x6b,
    qrDataLength & 0xff, // pL
    (qrDataLength >> 8) & 0xff, // pH
    0x31,
    0x50,
    0x30, // cn, fn, m
    ...encodedData,

    // Print QR code
    0x1d,
    0x28,
    0x6b,
    0x03,
    0x00,
    0x31,
    0x51,
    0x30,

    // Reset alignment to left
    0x1b,
    0x61,
    0x00, // Left alignment
  ]);
};

export type PaymentType =
  | "bank"
  | "online"
  | "paypal"
  | "cash"
  | "card"
  | "coupon"
  | "crypto"
  | "other";
export const getPaymentTypeLabel = (paymentType: PaymentType) => {
  switch (paymentType) {
    case "bank":
      return t`Bancno nakazilo`;
    case "online":
      return t`Spletno placilo`;
    case "paypal":
      return t`PayPal`;
    case "cash":
      return t`Gotovina`;
    case "card":
      return t`Kartica`;
    case "coupon":
      return t`Darilni bon`;
    case "crypto":
      return t`Kriptovaluta`;
    case "other":
      return t`Ostalo`;
  }
};
