import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeTextarea } from "@/Components/NextBase/LimeTextarea";
import { api } from "@/lib/api-client";
import { useForm } from "@mantine/form";
import {
  Button,
  Chip,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Skeleton,
  useDisclosure,
} from "@nextui-org/react";
import { notifications } from "@mantine/notifications";
import {
  getPaymentTypeLabel,
  PaymentType,
  preparePrintData,
} from "@/utils/pos-printer";
import {
  PrintReceiptType,
  usePosPrinterStore,
} from "@/stores/pos-printer-store";
import { toast } from "sonner";
import { useEffect } from "react";
import { SplitPaymentForm, SplitPaymentModal } from "./POS/SplitPaymentModal";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { cn } from "@/utils";

export const InvoiceDetailsModal = ({
  isAuthenticated,
  invoiceId,
  onClose,
}: {
  isAuthenticated: boolean;
  invoiceId?: string;
  onClose: () => void;
}) => {
  const {
    isOpen: isChangePaymentsModalOpen,
    onOpen: openChangePaymentsModal,
    onClose: closeChangePaymentsModal,
  } = useDisclosure();

  const {
    data: invoice,
    isPending,
    isFetching,
    isError,
    refetch,
    processedErrorMessage: getInvoiceErrorMessage,
  } = api.taxRegister.useGetOrganizationInvoice({
    invoiceId,
    supportsDiacritics: false,
  });

  const { isPrinting, printReceipt, connectToDevice, selectedPrinterType } =
    usePosPrinterStore((state) => state);

  const {
    mutateAsync: postSendInvoiceEmail,
    isPending: isPostSendInvoiceEmailPending,
    processedErrorMessage: postSendInvoiceEmailErrorMessage,
  } = api.taxRegister.usePostSendInvoiceEmail();

  const {
    mutateAsync: postCancelInvoice,
    isPending: isPostCancelInvoicePending,
    processedErrorMessage: postCancelInvoiceErrorMessage,
  } = api.taxRegister.usePostCancelInvoice();

  const {
    mutateAsync: putOrganizationInvoicePrintCount,
    isPending: isPutOrganizationInvoicePrintCountPending,
    processedErrorMessage: putOrganizationInvoicePrintCountErrorMessage,
  } = api.taxRegister.usePutOrganizationInvoicePrintCount();

  const {
    mutateAsync: putInvoicePaymentMethod,
    isPending: isPutInvoicePaymentMethodPending,
    processedErrorMessage: putInvoicePaymentMethodErrorMessage,
  } = api.taxRegister.usePutInvoicePaymentMethod();

  const {
    data: locationsData,
    isPending: isGetLocationsPending,
    processedErrorMessage: getLocationsErrorMessage,
  } = api.location.useGetLocations({
    page: 1,
    perPage: 100,
    sortBy: "labelDesc",
  });

  const handlePrintReceipt = async ({ type }: { type: PrintReceiptType }) => {
    if (!invoice) {
      notifications.show({
        message: t`Invoice not found`,
      });
      return;
    }

    const printerConfig =
      selectedPrinterType === "qztray"
        ? locationsData?.locations.find(
            (location) => location.locationId == invoice.locationId,
          )?.PosPrinter || null
        : null;

    const printReceiptPromise = async () => {
      await connectToDevice({ shouldThrow: true });

      const printData =
        type === "receipt"
          ? await preparePrintData(
              {
                type: "invoice",
                invoiceData: invoice,
                isCopy: true,
              },
              printerConfig,
            )
          : await preparePrintData(
              {
                type: "transaction-account",
                data: {
                  ...invoice,
                  amount: invoice.totalDue,
                  amountFormatted: invoice.totalDueFormatted,
                  currency: invoice.currencyId,
                  invoiceNumber: invoice.number,
                  name: invoice.additionalReceiptData._documentIssuer.name,
                  address:
                    invoice.additionalReceiptData._documentIssuer.address,
                  zip: invoice.additionalReceiptData._documentIssuer.zip,
                  city: invoice.additionalReceiptData._documentIssuer.city,
                  country:
                    invoice.additionalReceiptData._documentIssuer.country,
                  IBAN: invoice.additionalReceiptData._documentIssuer.IBAN,
                  SWIFT: invoice.additionalReceiptData._documentIssuer.SWIFT,
                  reference: `SI00 ${invoice.numberOnly}`,
                  dateDue: invoice.dateDue,
                },
              },
              printerConfig,
            );

      await printReceipt({
        printData,
        printerConfig,
        shouldThrow: true,
      });

      await putOrganizationInvoicePrintCount(invoiceId);
    };

    toast.promise(printReceiptPromise, {
      loading: t`Račun se tiska...`,
      success: async () => {
        await refetch();
        return t`Račun je bil tiskan`;
      },
      error: (data) => {
        return data?.toString() || t`Tiskanje računa ni uspelo`;
      },
    });
  };

  const form = useForm<{
    cancellationReason: string;
  }>({
    initialValues: {
      cancellationReason: "",
    },
    validate: {
      cancellationReason: (value) =>
        value.length > 0 ? null : t`Razlog stornacije je obvezen`,
    },
  });

  useEffect(() => {
    if (!invoiceId) {
      form.reset();
    }
  }, [invoiceId]);

  return (
    <>
      <Modal
        size="xl"
        isOpen={invoiceId != null && isAuthenticated}
        onClose={onClose}
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {invoice?.number}
              </ModalHeader>
              <ModalBody>
                <InvoiceDetailRow
                  label={t`Številka računa`}
                  value={invoice?.number || "XXXX-XXXXX"}
                  isSkeleton={isPending}
                />
                <InvoiceDetailRow
                  label={t`Datum in čas`}
                  value={invoice?.date || "XX. XX. XXXX XX:XX"}
                  isSkeleton={isPending}
                />
                <InvoiceDetailRow
                  label={t`Znesek`}
                  value={invoice?.totalFormatted || "XX.XXX,XX X"}
                  isSkeleton={isPending}
                />
                <InvoiceDetailRow
                  label={t`Način plačila`}
                  value={
                    invoice ? (
                      <>
                        {invoice.additionalReceiptData.payments.map(
                          (payment) => (
                            <div
                              key={payment.type}
                              className="flex w-full justify-between gap-2 text-sm text-gray-500"
                            >
                              <p className="w-fit text-left">
                                {getPaymentTypeLabel(
                                  payment.type as PaymentType,
                                )}
                                {payment.type === "coupon" ? (
                                  <span className="pl-1">{payment.code}</span>
                                ) : undefined}
                              </p>
                              <p className="w-full flex-1 text-right">
                                {payment.amountFormatted}
                              </p>
                            </div>
                          ),
                        )}
                        {invoice.additionalReceiptData.payments.length ===
                          0 && (
                          <p className="text-sm text-gray-500">
                            <Trans>Ni plačila</Trans>
                          </p>
                        )}
                      </>
                    ) : (
                      "XXXXXXXXX"
                    )
                  }
                  isSkeleton={isPending}
                />
                <InvoiceDetailRow
                  label={t`Stanje računa`}
                  value={(() => {
                    const color = (() => {
                      switch (invoice?.status.value) {
                        case "paid":
                          return "success";
                        case "paid_partially":
                          return "warning";
                        case "canceled":
                          return "danger";
                        case "not_paid":
                          return "danger";
                        default:
                          return "default";
                      }
                    })();

                    return (
                      <Chip
                        size="sm"
                        color={color}
                        variant="flat"
                        className={cn({
                          "pr-0": invoice?.status.value === "paid",
                        })}
                      >
                        {invoice?.status.label}
                      </Chip>
                    );
                  })()}
                  isSkeleton={isPending}
                />
                <InvoiceDetailRow
                  label={t`Izdal`}
                  value={
                    invoice?.additionalReceiptData.employeeData.name ||
                    "XXXX XXXXX"
                  }
                  isSkeleton={isPending}
                />
                {invoice?._documentClient.customerData && (
                  <InvoiceDetailRow
                    label={t`Končna stranka`}
                    value={
                      invoice._documentClient.customerData.name || "XXXX XXXXX"
                    }
                    isSkeleton={isPending}
                  />
                )}
                {invoice?._documentClient.companyData && (
                  <InvoiceDetailRow
                    label={t`Končno podjetje`}
                    value={
                      invoice._documentClient.companyData.companyName ||
                      "XXXX XXXXX"
                    }
                    isSkeleton={isPending}
                  />
                )}
                {invoice?.canceled && (
                  <div>
                    <p className="font-semibold uppercase text-red-500">
                      <Trans>Storniran</Trans>
                    </p>
                    {invoice.cancellationReason != null && (
                      <p className="text-sm text-gray-500">
                        {invoice.cancellationReason}
                      </p>
                    )}
                  </div>
                )}

                <Divider />

                <p className="font-semibold">Storitve</p>
                {invoice?.additionalReceiptData.invoiceData.items.map(
                  (item) => (
                    <div
                      key={item.name}
                      className="flex w-full justify-between gap-2 text-sm text-gray-500"
                    >
                      <p className="w-fit text-left">
                        {item.quantity}x {item.name}
                      </p>
                      <p className="w-full flex-1 text-right">
                        {item.totalWithTaxFormatted}
                      </p>
                    </div>
                  ),
                )}

                <Divider />
              </ModalBody>
              <ModalFooter className="flex flex-col">
                <Button
                  isDisabled={
                    isPending ||
                    isError ||
                    isPostCancelInvoicePending ||
                    isPostSendInvoiceEmailPending
                  }
                  isLoading={isPrinting || isFetching}
                  className="h-12"
                  color="primary"
                  onPress={() => handlePrintReceipt({ type: "receipt" })}
                >
                  <Trans>Ponovno tiskaj</Trans>
                </Button>
                {invoice?.additionalReceiptData.payments.length === 0 &&
                  !invoice.paidInFull && (
                    <Button
                      isDisabled={
                        isPending ||
                        isError ||
                        isPostCancelInvoicePending ||
                        isPostSendInvoiceEmailPending
                      }
                      isLoading={isPrinting || isFetching}
                      className="h-12"
                      color="primary"
                      onPress={() => handlePrintReceipt({ type: "UPN" })}
                    >
                      <Trans>Tiskaj UPN listek</Trans>
                    </Button>
                  )}
                {invoice?.additionalReceiptData.payments.length === 0 ? (
                  <Button
                    isDisabled={
                      isPending ||
                      isError ||
                      isPostCancelInvoicePending ||
                      isPostSendInvoiceEmailPending
                    }
                    isLoading={isFetching}
                    className="h-12"
                    color="primary"
                    onPress={async () => {
                      await putInvoicePaymentMethod({
                        invoiceId: invoice.id,
                        body: {
                          payments: [
                            {
                              type: "bank",
                              amountCents: Math.ceil(
                                invoice.totalWithTax * 100,
                              ),
                            },
                          ],
                        },
                      });

                      refetch();
                    }}
                  >
                    <Trans>Potrdi plačilo</Trans>
                  </Button>
                ) : undefined}
                {(invoice?.additionalReceiptData.payments || []).length > 0 &&
                invoice?.additionalReceiptData.payments.find(
                  (payment) => payment.type !== "bank",
                ) ? (
                  <Button
                    isDisabled={
                      isPending ||
                      isError ||
                      isPostCancelInvoicePending ||
                      isPostSendInvoiceEmailPending
                    }
                    className="h-12"
                    color="primary"
                    onPress={() => {
                      openChangePaymentsModal();
                    }}
                  >
                    <Trans>Spremeni način plačila</Trans>
                  </Button>
                ) : undefined}
                <Button
                  isDisabled={
                    isPending ||
                    isError ||
                    isPrinting ||
                    isPostCancelInvoicePending
                  }
                  isLoading={isPostSendInvoiceEmailPending}
                  className="h-12"
                  color="primary"
                  onPress={async () => {
                    if (!invoiceId) return;

                    await postSendInvoiceEmail({
                      invoiceId,
                      body: {
                        recipientEmail: "icevx1@gmail.com",
                      },
                    });
                  }}
                >
                  <Trans>Pošlji po e-pošti</Trans>
                </Button>

                {invoice?.canceled ? undefined : (
                  <>
                    <Divider className="my-4" />

                    <form
                      onSubmit={form.onSubmit(async (values) => {
                        if (!invoiceId) return;

                        await postCancelInvoice({ invoiceId, body: values });

                        refetch();
                      })}
                      className="flex flex-col gap-2"
                    >
                      <LimeTextarea
                        placeholder={t`Razlog stornacije`}
                        variant="bordered"
                        {...form.getInputProps("cancellationReason")}
                      />
                      <Button
                        isDisabled={
                          isPending ||
                          isError ||
                          isPrinting ||
                          isPostSendInvoiceEmailPending
                        }
                        isLoading={isPostCancelInvoicePending}
                        className="h-12"
                        color="primary"
                        type="submit"
                      >
                        <Trans>Storniraj</Trans>
                      </Button>
                    </form>
                  </>
                )}

                <LimeAlert
                  color="danger"
                  message={postSendInvoiceEmailErrorMessage}
                />
                <LimeAlert
                  color="danger"
                  message={postCancelInvoiceErrorMessage}
                />
                <LimeAlert color="danger" message={getInvoiceErrorMessage} />
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {invoice != null && (
        <SplitPaymentModal
          existingData={invoice.additionalReceiptData.payments.map((payment) =>
            payment.type === "coupon"
              ? {
                  type: "coupon",
                  amountCents: payment.amount * 100,
                  code: payment.code!,
                }
              : {
                  type: payment.type as Exclude<
                    SplitPaymentForm["options"][number]["type"],
                    "coupon"
                  >,
                  amountCents: payment.amount * 100,
                },
          )}
          handleClose={async (data) => {
            if (!data) {
              closeChangePaymentsModal();
              return;
            }

            const payments = data.options
              .filter(
                (option) =>
                  option.amountCents && Number(option.amountCents) > 0,
              ) // Filter entries where the value is valid and greater than 0
              .map((option) => {
                const type = option.type;

                if (type === "coupon") {
                  return {
                    type,
                    amountCents: Number(option.amountCents) * 100,
                    giftCardCode: option.code,
                  };
                }

                return {
                  type,
                  amountCents: Number(option.amountCents) * 100,
                };
              });

            await putInvoicePaymentMethod({
              invoiceId: invoice.id,
              body: {
                payments,
              },
            });

            refetch();
            closeChangePaymentsModal();
          }}
          isOpen={isChangePaymentsModalOpen}
          isLoading={isPutInvoicePaymentMethodPending}
          errorMessage={putInvoicePaymentMethodErrorMessage}
          totalPriceCents={invoice.totalWithTax * 100}
          locale="sl"
          currency="EUR"
          hideCoupon
          isChangingPaymentOnExistingInvoice
        />
      )}
    </>
  );
};

const InvoiceDetailRow = ({
  label,
  value,
  isSkeleton,
}: {
  label: string;
  value?: string | React.ReactNode;
  isSkeleton?: boolean;
}) => {
  return (
    <div className="flex w-full justify-between">
      <Skeleton isLoaded={!isSkeleton}>
        <p className="text-xs font-semibold">{label}</p>
      </Skeleton>
      <Skeleton isLoaded={!isSkeleton}>
        {typeof value === "string" ? (
          <p className="text-sm text-gray-500">{value}</p>
        ) : (
          value
        )}
      </Skeleton>
    </div>
  );
};
