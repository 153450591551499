import { t } from "@lingui/core/macro";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { api } from "@/lib/api-client";
import { useForm, UseFormReturnType } from "@mantine/form";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableColumnProps,
  TableHeader,
  TableRow,
  useDisclosure,
  CircularProgress,
  TableBodyProps,
  Selection,
} from "@nextui-org/react";
import React, {
  FormEvent,
  Key,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { produce } from "immer";
import { notifications } from "@mantine/notifications";
import { AxiosError } from "axios";
import { Color } from "@/types/colors";
import { useSearchParams } from "react-router-dom";
import { PostOrganizationInvoice } from "@/server-types";
import { POSAuthorizationDialog } from "./AuthorizationDialog";
import { BottomActions, BottomActionsProps } from "../BottomActions";
import {
  CategoriesServicesDrawer,
  CategoryServicesDrawerProps,
} from "./CategoryServicesDrawer";
import { CustomerModal } from "./CustomerModal";
import { DiscountModal } from "./DiscountModal";
import { ExtraMenuModal } from "./ExtraMenuModal";
import { SplitPaymentForm, SplitPaymentModal } from "./SplitPaymentModal";
import { formatCurrency } from "../../../../../../../shared/utils/utils";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";
import { RiCalendarEventFill } from "react-icons/ri";
import { ReceiptText } from "lucide-react";
import { calculateFinalArticlePrice, calculateFinalInvoicePrice } from "./util";
import { usePosPrinterStore } from "@/stores/pos-printer-store";
import { TaxRegisterAuthorizationDialog } from "../../AuthorizationDialog";
import { DesktopCategoryServices } from "./DesktopCategoryServices";
import { useDebouncedValue } from "@mantine/hooks";
import { GiftCardManageModal } from "./GiftCardManageModal";
import { GiftCard } from "@/server-types";
import { toast } from "sonner";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { preparePrintData } from "@/utils/pos-printer";

export type NewGiftCardData = Pick<
  GiftCard,
  "code" | "initialAmountCents" | "expiryDate" | "note"
> & { key: string };

export type Article = {
  key: string;
  id: number;
  type: NonNullable<
    PostOrganizationInvoice["body"]["_documentItems"]
  >[number]["type"];
  article_name: string;
  quantity: number;
  maxQuantity?: number;
  priceCents: number;
  discountAmountCents?: number;
  discountPercentage?: number;
  disallowEditing?: boolean;

  userAppointmentId?: number;
};

type TableColumn = {
  key: string;
  label: string;
  maxWidth?: TableColumnProps<Record<string, never>>["width"];
};

export type POSForm = {
  articles: Article[];
  customerId: number | undefined;
  companyId: number | undefined;

  discountAmountCents?: number;
  discountPercentage?: number;

  businessPremiseId: string | undefined;
  electronicDeviceId: string | undefined;
  giftCardCodes: string[];

  splitPaymentOptions: SplitPaymentForm["options"];
  createGiftCardSplitPaymentOptions: SplitPaymentForm["createGiftCardOptions"];

  sendReceiptViaEmail: boolean;

  giftCards: NewGiftCardData[];
};

export const POS = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userAppointmentId = searchParams.has("uaid")
    ? Number(searchParams.get("uaid"))
    : undefined;

  const shouldOpenCreateGiftCardDialog = searchParams.has("create-gc");

  const splitFormSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const bottomActionsRef = useRef<HTMLDivElement>(null);

  const {
    preferredLanguage: { userPreferredLanguage },
  } = usePreferredLanguageStore();

  const [addingArticleCategoryId, setAddingArticleCategoryId] = useState<
    number | null
  >(null);
  const [addingArticleCategoryType, setAddingArticleCategoryType] = useState<
    CategoryServicesDrawerProps["preSelectedCategoryType"] | null
  >(null);

  const [selectedArticleKey, setSelectedArticleKey] = useState<string | null>(
    null,
  );

  const [isProcessingReceipt, setIsProcessingReceipt] = useState(false);

  const [bottomActionsHeight, setBottomActionsHeight] = useState(0);

  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebouncedValue(search, 350);

  const {
    isOpen: isArticleDiscountModalOpen,
    onOpen: onOpenArticleDiscountModal,
    onClose: onCloseArticleDiscountModal,
  } = useDisclosure();

  const {
    isOpen: isInvoiceDiscountModalOpen,
    onOpen: onOpenInvoiceDiscountModal,
    onClose: onCloseInvoiceDiscountModal,
  } = useDisclosure();

  const {
    isOpen: isCustomerModalOpen,
    onOpen: onOpenCustomerModal,
    onClose: onCloseCustomerModal,
  } = useDisclosure();

  const {
    isOpen: isSplitPaymentModalOpen,
    onOpen: onOpenSplitPaymentModal,
    onClose: onCloseSplitPaymentModal,
  } = useDisclosure();

  const {
    isOpen: isGiftCardCreationModalOpen,
    onOpen: onOpenGiftCardCreationModal,
    onClose: onCloseGiftCardCreationModal,
  } = useDisclosure();

  const {
    isOpen: isExtraMenuOpen,
    onOpen: onOpenExtraMenu,
    onClose: onCloseExtraMenu,
  } = useDisclosure();

  const form = useForm<POSForm>({
    initialValues: {
      articles: [],
      customerId: undefined,
      companyId: undefined,
      discountAmountCents: undefined,
      discountPercentage: undefined,
      businessPremiseId: undefined,
      electronicDeviceId: undefined,
      splitPaymentOptions: [],
      createGiftCardSplitPaymentOptions: [],
      sendReceiptViaEmail: false,
      giftCards: [],
      giftCardCodes: [],
    },

    validate: {
      articles: (value) => {
        if (value.length === 0) {
          return t`Izberite artikle`;
        }

        return null;
      },

      discountAmountCents: (value) => {
        if (value != null && value < 0) {
          return t`Popust ne sme biti manj kot 0`;
        }

        const totalArticlesPrice = form
          .getValues()
          .articles.reduce((a, b) => a + calculateFinalArticlePrice(b), 0);
        if (value != null && value > totalArticlesPrice) {
          return t`Popust ne sme biti več kot znesek artikla`;
        }

        if (form.getValues().discountPercentage != null && value != null) {
          return t`Ne smete imeti količinskega in procentnega popusta hkrati`;
        }

        return null;
      },

      discountPercentage: (value) => {
        if (value != null && value < 0) {
          return t`Popust ne sme biti manj kot 0`;
        }

        if (value != null && value > 100) {
          return t`Popust ne sme biti več kot 100`;
        }

        if (form.getValues().discountAmountCents != null && value != null) {
          return t`Ne smete imeti količinskega in procentnega popusta hkrati`;
        }

        return null;
      },

      sendReceiptViaEmail: (value) => {
        if (!value) return null;

        if (form.getValues().customerId == null) {
          return t`Stranka je obvezna za pošiljanje računa preko e-pošte`;
        }

        const customer = customerData?.[0];
        if (customer && !customer.email) {
          return t`Stranka mora imeti e-poštni naslov`;
        }
      },
    },
  });

  const { mutateAsync: deleteCookie, isPending: isDeletingCookie } =
    api.cookie.useDeleteHttpCookie();

  const { data: customerData } = api.customer.useGetCustomers({
    customerId: form.getValues().customerId,
  });

  const { data: companyData } = api.company.useGetCompanies({
    companyId: form.getValues().companyId,
  });

  const {
    data: posAuthData,
    isFetching: isFetchingPosAuth,
    refetch: refetchPosAuth,
  } = api.cookie.useGetPosToken();

  const {
    data: serviceCategories,
    isFetching: isPendingServiceCategories,
    processedErrorMessage: serviceCategoriesErrorMessage,
  } = api.category.useGetCategories({});

  const {
    data: servicesData,
    isFetching: isPendingServices,
    processedErrorMessage: servicesErrorMessage,
  } = api.service.useGetServices({
    currencyBusinessPremiseId: form.getValues().businessPremiseId,
    search: debouncedSearch,
  });

  const { data: productsData, processedErrorMessage: productsErrorMessage } =
    api.product.useGetProducts({
      businessPremiseId: form.getValues().businessPremiseId,
      search: debouncedSearch,
      productType: "SALES",
    });

  const {
    data: productCategories,
    processedErrorMessage: productCategoriesErrorMessage,
  } = api.product.useGetProductCategories({});

  const allCategories: BottomActionsProps["categories"] = (() => {
    if (!serviceCategories && !productCategories) return undefined;

    const productCats =
      productCategories?.map((pc) => ({
        tagId: pc.id,
        localizedName: pc.name,
        color: pc.color,
        type: "product" as NonNullable<
          BottomActionsProps["categories"]
        >[number]["type"],
      })) || [];
    const serviceCats =
      serviceCategories?.map((sc) => ({
        ...sc,
        type: "service" as NonNullable<
          BottomActionsProps["categories"]
        >[number]["type"],
      })) || [];

    return [
      ...productCats,
      ...serviceCats,
      {
        tagId: -1,
        localizedName: t`Ostalo`,
        color: null,
        type: "all" as NonNullable<
          BottomActionsProps["categories"]
        >[number]["type"],
      },
    ];
  })();

  const {
    mutateAsync: postInvoice,
    isPending: isPendingPostInvoice,
    processedErrorMessage: postInvoiceErrorMessage,
  } = api.taxRegister.usePostOrganizationInvoice();

  const { data: currency } = api.values.useGetCurrency({
    businessPremiseId: form.getValues().businessPremiseId,
  });

  const {
    data: selectedAppointmentData,
    isPending: isPendingSelectedAppointmentServices,
  } = api.appointments.useGetAppointmentByUserAppointmentId(userAppointmentId);

  const { mutateAsync: putInvoicePrintCount } =
    api.taxRegister.usePutOrganizationInvoicePrintCount();

  const { printReceipt, connectToDevice, selectedPrinterType } =
    usePosPrinterStore((state) => state);

  const {
    data: locationsData,
    isPending: isGetLocationsPending,
    processedErrorMessage: getLocationsErrorMessage,
  } = api.location.useGetLocations({
    page: 1,
    perPage: 100,
    sortBy: "labelDesc",
  });

  useEffect(() => {
    if (!selectedAppointmentData) return;

    form.setFieldValue(
      "articles",
      selectedAppointmentData.services.map((service) => ({
        key: `service-${service.serviceId.toString()}`,
        userAppointmentId: service.userAppointmentId,
        id: service.serviceId,
        type: "service" as const,
        article_name: service.name,
        priceCents: service.priceCents,
        quantity: 1,
        disallowEditing: true,
        discountAmountCents: service.discountAmountCents || undefined,
        discountPercentage: service.discountPercentage || undefined,
      })),
    );
    form.setFieldValue("customerId", selectedAppointmentData.customerId);
  }, [selectedAppointmentData]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      deleteCookie({
        cookieName: "pos-token",
      });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useLayoutEffect(() => {
    const getBottomActionsHeight = () => {
      const bottomActionsHeight = bottomActionsRef.current?.clientHeight || 0;

      setBottomActionsHeight(bottomActionsHeight);
    };

    // Initial calculation
    getBottomActionsHeight();

    // Re-calculate on window resize
    window.addEventListener("resize", getBottomActionsHeight);
    return () => window.removeEventListener("resize", getBottomActionsHeight);
  }, [bottomActionsRef]);

  const handleSubmit = async (
    values: POSForm,
    e: FormEvent<HTMLFormElement> | undefined,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const paymentType = (e?.nativeEvent as any).submitter.value as
      | "cash"
      | "card"
      | "bank"
      | "split";

    const _furs: PostOrganizationInvoice["body"]["_furs"] = {
      businessPremiseId: values.businessPremiseId, // ! id field ; not businessPremiseId on business premise fetch
      electronicDeviceId: values.electronicDeviceId,
    };

    const _documentItems: PostOrganizationInvoice["body"]["_documentItems"] =
      values.articles.map((article) => ({
        id: article.id,
        type: article.type,
        discountPercentage: article.discountPercentage,
        discountCents: article.discountAmountCents,
        quantity: article.quantity,
        userAppointmentId: article.userAppointmentId,
        amountCents: article.priceCents,
      }));

    const payments: PostOrganizationInvoice["body"]["payments"] = (() => {
      if (
        paymentType === "cash" ||
        paymentType === "card" ||
        paymentType === "bank"
      ) {
        return [
          {
            type: paymentType,
          },
        ];
      }

      if (paymentType === "split") {
        const splitPayments = values.splitPaymentOptions
          .filter(
            (option) => option.amountCents && Number(option.amountCents) > 0,
          ) // Filter entries where the value is valid and greater than 0
          .map((option) => {
            const type = option.type;

            if (type === "coupon") {
              return {
                type,
                amountCents: Number(option.amountCents) * 100,
                giftCardCode: option.code,
              };
            }

            return {
              type,
              amountCents: Number(option.amountCents) * 100,
            };
          });

        return splitPayments;
      }

      throw new Error(`Invalid payment type ${paymentType}`);
    })();

    const data: PostOrganizationInvoice["body"] = {
      _furs,
      _documentItems,
      companyId: values.companyId,
      customerId: values.customerId,
      payments,
      discountCents: values.discountAmountCents,
      discountPercentage: values.discountPercentage,
      sendReceiptViaEmail: values.sendReceiptViaEmail,
      createGiftCardsData: values.giftCards
        ? {
            giftCards: values.giftCards,
            payments: values.createGiftCardSplitPaymentOptions,
          }
        : undefined,
    };

    try {
      const response = await postInvoice(data);

      if (!response.success) return;

      const invoiceData = response.invoiceData;

      onCloseSplitPaymentModal();
      onCloseExtraMenu();
      setSelectedArticleKey(null);

      const sParams = searchParams;
      sParams.delete("auid");
      sParams.delete("uaid");
      sParams.delete("create-gc");
      setSearchParams(sParams);

      toast.success(t`Račun je bil uspešno ustvarjen`);

      if (
        response.requireReauthorization &&
        (values.sendReceiptViaEmail || !invoiceData)
      ) {
        form.reset();
      }

      const locationId = invoiceData
        ? invoiceData.locationId
        : locationsData?.locations.find((location) =>
            location.BusinessPremise.some(
              (bp) => bp.businessPremiseId == values.businessPremiseId,
            ),
          )?.locationId;
      if (!locationId) {
        throw new Error("Location for printing not found");
      }

      const printerConfig =
        selectedPrinterType === "qztray"
          ? locationsData?.locations.find(
              (location) => location.locationId == locationId,
            )?.PosPrinter || null
          : null;

      const receipts: Array<() => Promise<void>> = [];

      if (!values.sendReceiptViaEmail && invoiceData) {
        setIsProcessingReceipt(true);

        const printReceiptPromise = async () => {
          const printData = await preparePrintData(
            {
              type: "invoice",
              invoiceData,
              isCopy: false,
            },
            printerConfig,
          );

          await printReceipt({
            printData,
            printerConfig,
            shouldThrow: true,
          });

          await putInvoicePrintCount(invoiceData.id);
        };
        receipts.push(printReceiptPromise);

        // Add transaction account receipt if needed
        if (
          invoiceData.additionalReceiptData.payments.length === 0 &&
          !invoiceData.paidInFull
        ) {
          const printTrrReceiptPromise = async () => {
            const printData = await preparePrintData(
              {
                type: "transaction-account",
                data: {
                  ...invoiceData,
                  amount: invoiceData.totalDue,
                  amountFormatted: invoiceData.totalDueFormatted,
                  currency: invoiceData.currencyId,
                  invoiceNumber: invoiceData.number,
                  name: invoiceData.additionalReceiptData._documentIssuer.name,
                  address:
                    invoiceData.additionalReceiptData._documentIssuer.address,
                  zip: invoiceData.additionalReceiptData._documentIssuer.zip,
                  city: invoiceData.additionalReceiptData._documentIssuer.city,
                  country:
                    invoiceData.additionalReceiptData._documentIssuer.country,
                  IBAN: invoiceData.additionalReceiptData._documentIssuer.IBAN,
                  SWIFT:
                    invoiceData.additionalReceiptData._documentIssuer.SWIFT,
                  reference: `SI00 ${invoiceData.numberOnly}`,
                  dateDue: invoiceData.dateDue,
                },
              },
              printerConfig,
            );

            await printReceipt({
              printData,
              printerConfig,
              shouldThrow: true,
            });
          };
          receipts.push(printTrrReceiptPromise);
        }
      }

      const giftCardPrintData = response.giftCardPrintData;
      if (giftCardPrintData) {
        const printGcReceiptPromises = giftCardPrintData.issuedGiftCards.map(
          (gc) => async () => {
            const printData = await preparePrintData(
              {
                type: "gift-card",
                data: {
                  operatorLabel: giftCardPrintData.operatorLabel,
                  organization: giftCardPrintData.organization,
                  giftCard: gc,
                  currencyId: giftCardPrintData.currencyId,
                },
              },
              printerConfig,
            );

            await printReceipt({
              printData,
              printerConfig,
              shouldThrow: true,
            });
          },
        );

        receipts.push(...printGcReceiptPromises);

        const printGcReceiptPromise = async () => {
          const printData = await preparePrintData(
            {
              type: "gift-card-receipt",
              data: {
                operatorLabel: giftCardPrintData.operatorLabel,
                organization: giftCardPrintData.organization,
                issuedGiftCards: giftCardPrintData.issuedGiftCards,
                payments: giftCardPrintData.payments,
                currencyId: giftCardPrintData.currencyId,
                dateTime: giftCardPrintData.dateTime,
                location: giftCardPrintData.location,
              },
            },
            printerConfig,
          );

          await printReceipt({
            printData,
            printerConfig,
            shouldThrow: true,
          });
        };

        receipts.push(printGcReceiptPromise);
      }

      const printAllReceiptsSequentially = async (
        receipts: Array<() => Promise<void>>,
      ) => {
        for (const printFunc of receipts) {
          await printFunc(); // each function runs its own `preparePrintData` + `printReceipt`
        }
      };

      const printAll = async () => {
        await connectToDevice({ shouldThrow: true });

        await printAllReceiptsSequentially(receipts);

        // After ALL printing is done successfully:
        // 1) handle reauthorization if needed
        if (response.requireReauthorization) {
          await deleteCookie({ cookieName: "pos-token" });
          refetchPosAuth();
          form.reset();
        } else {
          // If not reauth, just reset form and restore premise & device
          const { businessPremiseId, electronicDeviceId } = values;
          form.reset();
          form.setFieldValue("businessPremiseId", businessPremiseId);
          form.setFieldValue("electronicDeviceId", electronicDeviceId);
        }

        setIsProcessingReceipt(false);

        return t`Račun je bil uspešno natisnjen`; // success message
      };

      toast.promise(printAll(), {
        loading: t`Račun se tiska...`,
        success: (message) => message,
        error: (err) => err?.toString() || t`Tiskanje računa ni uspelo`,
      });
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err?.response?.data?.code === "MISSING_TAX_NUMBER") return;

        notifications.show({
          message: err.message,
          color: Color.Error,
        });
      }
    }
  };

  const handleAddArticleToInvoice = (itemId?: number, itemType?: string) => {
    if (itemId) {
      setSearch("");

      if (itemType === "service") {
        const service = servicesData?.services?.find(
          (s) => s.serviceId == itemId,
        );

        if (!service) return;

        form.setFieldValue("articles", [
          ...form.getValues().articles,
          {
            id: service.serviceId,
            type: "service" as const,
            key: `service-${itemId}`,
            article_name: service.name,
            quantity: 1,
            priceCents: service.priceWithTax,
          },
        ]);
        return;
      }

      if (itemType === "product") {
        const product = productsData?.products?.find((p) => p.id == itemId);

        if (!product) return;

        form.setFieldValue("articles", [
          ...form.getValues().articles,
          {
            id: product.id,
            type: "product" as const,
            key: `product-${itemId}`,
            article_name: product.name,
            quantity: 1,
            priceCents: product.salesProductData?.grossPriceCents || 0,
            maxQuantity: product.salesProductData?.stockQuantity,
          },
        ]);
        return;
      }
    }
  };

  const {
    totalInvoicePriceCents,
    totalInvoicePriceFormatted,
    totalDiscountedInvoicePriceCents,
    totalDiscountedInvoicePriceFormatted,
  } = (() => {
    const articles = form.getValues().articles;
    const invoiceDiscountCents = form.getValues().discountAmountCents;
    const invoiceDiscountPercentage = form.getValues().discountPercentage;
    const locale = userPreferredLanguage;

    const hasAnyArticleDiscount = articles.some(
      (article) =>
        article.discountAmountCents != null ||
        article.discountPercentage != null,
    );

    const totalPrice = articles.reduce((acc, article) => {
      return acc + article.priceCents * article.quantity;
    }, 0);

    if (
      !hasAnyArticleDiscount &&
      invoiceDiscountCents == null &&
      invoiceDiscountPercentage == null
    ) {
      return {
        totalInvoicePriceCents: totalPrice,
        totalDiscountedInvoicePriceCents: undefined,
        totalInvoicePriceFormatted: formatCurrency({
          amount: totalPrice / 100,
          currency: currency || "EUR",
          locale,
          fractionDigits: 3,
        }),
        totalDiscountedInvoicePriceFormatted: undefined,
      };
    }

    const discountSumCents = articles.reduce((acc, article) => {
      const hasDiscount =
        article.discountAmountCents != null ||
        article.discountPercentage != null;

      if (!hasDiscount) {
        return acc + article.priceCents * article.quantity;
      }

      return acc + calculateFinalArticlePrice(article);
    }, 0);

    const finalDiscountSumCents = (() => {
      if (invoiceDiscountCents != null) {
        return discountSumCents - invoiceDiscountCents;
      }

      if (invoiceDiscountPercentage != null) {
        return (
          discountSumCents -
          (discountSumCents * invoiceDiscountPercentage) / 100
        );
      }

      return discountSumCents;
    })();

    return {
      totalInvoicePriceCents: totalPrice,
      totalDiscountedInvoicePriceCents: finalDiscountSumCents,
      totalInvoicePriceFormatted: formatCurrency({
        amount: totalPrice / 100,
        currency: currency || "EUR",
        locale,
        fractionDigits: 3,
      }),
      totalDiscountedInvoicePriceFormatted: formatCurrency({
        amount: finalDiscountSumCents / 100,
        currency: currency || "EUR",
        locale,
        fractionDigits: 3,
      }),
    };
  })();

  const customerName = (() => {
    if (customerData != null) {
      const customer = customerData[0];

      if (!customer) {
        return undefined;
      }

      return `${customer.name} ${customer.lastName}`;
    }

    if (companyData != null) {
      return companyData[0]?.companyName;
    }
  })();

  const canCreateInvoice = form.isValid();

  const newGiftCards = form
    .getValues()
    .articles.filter((article) => article.type === "gift-card");

  const newGiftCardsTotalAmountCents = newGiftCards.reduce((acc, giftCard) => {
    return acc + giftCard.priceCents;
  }, 0);

  return (
    <>
      <TaxRegisterAuthorizationDialog />

      {posAuthData?.userId != null &&
        form.getValues().businessPremiseId == null && (
          <POSAuthorizationDialog
            authorizedUserId={posAuthData.userId}
            handleSuccessfulAuthorization={({
              businessPremiseId,
              electronicDeviceId,
            }) => {
              form.setFieldValue("businessPremiseId", businessPremiseId);
              form.setFieldValue("electronicDeviceId", electronicDeviceId);

              if (shouldOpenCreateGiftCardDialog) {
                onOpenGiftCardCreationModal();
              }
            }}
            locationId={selectedAppointmentData?.locationId}
            businessPremiseId={form.getValues().businessPremiseId}
          />
        )}

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <LimePageHeader
          title={t`POS${posAuthData?.isTestEnvironment ? " - TEST ENV" : undefined}`}
        />

        <div
          className="flex w-full flex-row"
          style={{ minHeight: "calc(100dvh - 65px)" }}
        >
          {/* Left Column */}
          <div className="flex w-full flex-col lg:w-2/4">
            <div
              className="flex-grow md:px-8"
              style={{
                maxHeight: `calc(100dvh - ${bottomActionsHeight}px - 65px)`,
                overflowY: "auto",
              }}
            >
              <ArticleTable
                form={form}
                currency={currency || "EUR"}
                locale={userPreferredLanguage}
                loadingState={
                  userAppointmentId != null &&
                  isPendingSelectedAppointmentServices
                    ? "loading"
                    : undefined
                }
                onSelectionChange={(key) => {
                  const articleKey = (key as Set<Key>).entries().next()
                    .value?.[1];
                  setSelectedArticleKey(
                    articleKey ? articleKey.toString() : null,
                  );
                }}
                selectedAppointmentServices={
                  selectedAppointmentData?.services || []
                }
                selectedArticleKey={selectedArticleKey}
              />
            </div>

            {/* BottomActions Sticking to the Bottom */}
            <div className="w-full">
              <LimeAlert
                color="danger"
                message={servicesErrorMessage}
                className="mx-2 mb-2"
              />
              <LimeAlert
                color="danger"
                message={serviceCategoriesErrorMessage}
                className="mx-2 mb-2"
              />
              <LimeAlert
                color="danger"
                message={productsErrorMessage}
                className="mx-2 mb-2"
              />
              <LimeAlert
                color="danger"
                message={productCategoriesErrorMessage}
                className="mx-2 mb-2"
              />
              <BottomActions
                bottomActionsRef={bottomActionsRef}
                onGiftCardsClick={onOpenGiftCardCreationModal}
                isCreatingInvoice={isPendingPostInvoice || isProcessingReceipt}
                handleCategoryClick={(categoryId, categoryType) => {
                  setAddingArticleCategoryId(categoryId || null);
                  setAddingArticleCategoryType(categoryType || null);
                }}
                postInvoiceErrorMessage={postInvoiceErrorMessage}
                categories={allCategories}
                selectedArticle={form
                  .getValues()
                  .articles.find(
                    (article) => article.key == selectedArticleKey,
                  )}
                customerName={customerName}
                canCreateInvoice={canCreateInvoice}
                totalDiscountedInvoicePriceCents={
                  totalDiscountedInvoicePriceCents
                }
                totalInvoicePriceFormatted={totalInvoicePriceFormatted}
                totalDiscountedInvoicePriceFormatted={
                  totalDiscountedInvoicePriceFormatted
                }
                form={form}
                handleDeleteArticle={() => {
                  const articleIndex = form
                    .getValues()
                    .articles.findIndex(
                      (article) => article.key == selectedArticleKey,
                    );

                  form.removeListItem("articles", articleIndex);

                  const giftCardIndex = form
                    .getValues()
                    .giftCards.findIndex(
                      (giftCard) => giftCard.key == selectedArticleKey,
                    );
                  if (giftCardIndex !== -1) {
                    form.removeListItem("giftCards", giftCardIndex);
                  }

                  setSelectedArticleKey(null);
                }}
                handleChangeArticleQuantity={(shouldIncrease) => {
                  if (selectedArticleKey) {
                    const updatedArticlesArray = produce(
                      form.getValues().articles,
                      (draft) => {
                        const index = draft.findIndex(
                          (article) => article.key == selectedArticleKey,
                        );

                        if (index >= 0) {
                          const article = draft[index];

                          const canChangeQuantity = (() => {
                            if (!shouldIncrease) {
                              return article.quantity > 1;
                            }

                            return shouldIncrease ? true : article.quantity > 1; // don't allow to go below 1
                          })();

                          if (canChangeQuantity) {
                            article.quantity += shouldIncrease ? 1 : -1;
                          }
                        }
                      },
                    );

                    form.setFieldValue("articles", updatedArticlesArray);
                  }
                }}
                handleDiscountArticle={() => {
                  onOpenArticleDiscountModal();
                }}
                handleOpenExtraMenu={() => {
                  onOpenExtraMenu();
                }}
                handleAddDiscountToInvoice={onOpenInvoiceDiscountModal}
                handlePickCustomer={onOpenCustomerModal}
                handleSplitPayment={onOpenSplitPaymentModal}
                isPendingCategories={isPendingServiceCategories}
                isPendingServices={isPendingServices}
              />
            </div>
          </div>

          {/* Right Column - Hidden on Small Screens */}
          <div className="hidden max-h-[calc(100dvh-65px)] flex-1 overflow-y-auto px-2 pt-4 lg:block">
            <DesktopCategoryServices
              categories={allCategories}
              services={servicesData?.services}
              products={productsData?.products}
              selectedServiceIds={form
                .getValues()
                .articles.filter((a) => a.type === "service")
                .map((a) => a.id)}
              selectedProductIds={form
                .getValues()
                .articles.filter((a) => a.type === "product")
                .map((a) => a.id)}
              handleClose={handleAddArticleToInvoice}
              setSearch={setSearch}
              search={search}
              onGiftCardsClick={onOpenGiftCardCreationModal}
            />
          </div>
        </div>

        <CategoriesServicesDrawer
          categories={allCategories}
          services={servicesData?.services}
          products={productsData?.products}
          selectedServiceIds={form
            .getValues()
            .articles.filter((a) => a.type === "service")
            .map((a) => a.id)}
          handleClose={(itemId, itemType) => {
            setAddingArticleCategoryId(null);
            setAddingArticleCategoryType(null);
            setSearch("");

            handleAddArticleToInvoice(itemId, itemType);
          }}
          preSelectedCategoryId={addingArticleCategoryId || undefined}
          preSelectedCategoryType={addingArticleCategoryType || undefined}
          selectedProductIds={form
            .getValues()
            .articles.filter((a) => a.type === "product")
            .map((a) => a.id)}
          isOpen={
            addingArticleCategoryId != null || addingArticleCategoryType != null
          }
          setSearch={setSearch}
          search={search}
        />

        <ExtraMenuModal
          isOpen={isExtraMenuOpen}
          handleClose={onCloseExtraMenu}
          handleAddDiscountToInvoice={onOpenInvoiceDiscountModal}
          handlePickCustomer={onOpenCustomerModal}
          handleSplitPayment={onOpenSplitPaymentModal}
          canCreateInvoice={canCreateInvoice}
        />

        <SplitPaymentModal
          isOpen={isSplitPaymentModalOpen}
          handleClose={(data) => {
            if (data) {
              const { options, createGiftCardOptions } = data;

              form.setFieldValue("splitPaymentOptions", options);
              form.setFieldValue(
                "createGiftCardSplitPaymentOptions",
                createGiftCardOptions || [],
              );

              if (!splitFormSubmitButtonRef.current) {
                throw new Error(t`Napaka pri pošiljanju obrazca`);
              }

              splitFormSubmitButtonRef.current.click();
            } else {
              onCloseSplitPaymentModal();
            }
          }}
          isLoading={isPendingPostInvoice}
          currency={currency || "EUR"}
          locale={userPreferredLanguage}
          totalPriceCents={
            totalInvoicePriceCents - newGiftCardsTotalAmountCents
          }
          totalDiscountInvoicePriceCents={
            totalDiscountedInvoicePriceCents
              ? totalDiscountedInvoicePriceCents - newGiftCardsTotalAmountCents
              : undefined
          }
          errorMessage={postInvoiceErrorMessage}
          createGiftCardSection={
            newGiftCards.length > 0
              ? {
                  amountCents: newGiftCards.reduce(
                    (acc, curr) => acc + curr.priceCents,
                    0,
                  ),
                }
              : undefined
          }
        />

        <GiftCardManageModal
          isOpen={isGiftCardCreationModalOpen}
          handleClose={(data) => {
            if (data.giftCardData) {
              form.setFieldValue("giftCards", [
                ...form.getValues().giftCards,
                data.giftCardData,
              ]);

              const giftCardArticle: Article = {
                key: `giftcard-${data.giftCardData.code}`,
                id: Math.random(),
                type: "gift-card",
                article_name: t`Darilni bon - ${data.giftCardData.code}`,
                quantity: 1,
                priceCents: data.giftCardData.initialAmountCents,
              };

              form.setFieldValue("articles", [
                ...form.getValues().articles,
                giftCardArticle,
              ]);
            }

            onCloseGiftCardCreationModal();
          }}
        />

        <CustomerModal
          isOpen={isCustomerModalOpen}
          preSelectedCustomerId={form.getValues().customerId}
          handleClose={(data?: { customerId?: number; companyId?: number }) => {
            onCloseCustomerModal();

            if (data?.customerId != null) {
              form.setFieldValue("customerId", data.customerId);
              onCloseExtraMenu();
            }

            if (data?.companyId != null) {
              form.setFieldValue("companyId", data.companyId);
              onCloseExtraMenu();
            }
          }}
        />

        <DiscountModal
          currency={currency || "EUR"}
          isOpen={isInvoiceDiscountModalOpen}
          existingData={{
            priceCents: form.getValues().articles.reduce((acc, curr) => {
              return acc + calculateFinalArticlePrice(curr);
            }, 0),
            discountAmountCents: form.getValues().discountAmountCents,
            discountPercentage: form.getValues().discountPercentage,
          }}
          handleClose={(data) => {
            if (data) {
              if (data.amount === 0) {
                form.setFieldValue("discountAmountCents", undefined);
                form.setFieldValue("discountPercentage", undefined);
              }

              if (data.type === "amount") {
                form.setFieldValue("discountAmountCents", Number(data.amount));
                form.setFieldValue("discountPercentage", undefined);
              } else {
                form.setFieldValue("discountPercentage", Number(data.amount));
                form.setFieldValue("discountAmountCents", undefined);
              }

              onCloseExtraMenu();
            }

            onCloseInvoiceDiscountModal();
          }}
        />

        <DiscountModal
          currency={currency || "EUR"}
          isOpen={isArticleDiscountModalOpen}
          existingData={form
            .getValues()
            .articles.find((a) => a.key == selectedArticleKey)}
          maxDiscountAmountCents={calculateFinalInvoicePrice({
            articles: form.getValues().articles,
            invoiceDiscountAmountCents: form.getValues().discountAmountCents,
            invoiceDiscountPercentage: form.getValues().discountPercentage,
            excludeDiscountsForArticleIds: form
              .getValues()
              .articles.find((a) => a.key == selectedArticleKey)
              ? [
                  form
                    .getValues()
                    .articles.find((a) => a.key == selectedArticleKey)!.id,
                ]
              : [],
          })}
          handleClose={(data) => {
            if (data) {
              const existingArticle = form
                .getValues()
                .articles.find((a) => a.key == selectedArticleKey);

              if (existingArticle) {
                const mutatedArticle = produce(existingArticle, (draft) => {
                  if (data.amount == 0) {
                    draft.discountAmountCents = undefined;
                    draft.discountPercentage = undefined;
                    return draft;
                  }

                  if (data.type === "amount") {
                    draft.discountAmountCents = Number(data.amount);
                    draft.discountPercentage = undefined;
                  } else {
                    draft.discountPercentage = Number(data.amount);
                    draft.discountAmountCents = undefined;
                  }
                });

                form.setFieldValue(
                  "articles",
                  form
                    .getValues()
                    .articles.map((a) =>
                      a.key == selectedArticleKey ? mutatedArticle : a,
                    ),
                );
              }
            }

            onCloseArticleDiscountModal();
          }}
        />
        <button
          type="submit"
          className="hidden"
          value={"split"}
          ref={splitFormSubmitButtonRef}
        ></button>
      </form>
    </>
  );
};

const ArticleTable = ({
  selectedArticleKey,
  onSelectionChange,
  selectedAppointmentServices,
  form,
  loadingState,
  currency,
  locale,
}: {
  selectedArticleKey: string | null;
  onSelectionChange: (keys: Selection) => void;
  selectedAppointmentServices: NonNullable<
    ReturnType<
      typeof api.appointments.useGetAppointmentByUserAppointmentId
    >["data"]
  >["services"];
  form: UseFormReturnType<POSForm>;
  loadingState: TableBodyProps<Record<string, never>>["loadingState"];
  currency: string;
  locale: string;
}) => {
  const columns: TableColumn[] = [
    {
      key: "article_name",
      label: t`Artikel`,
      maxWidth: undefined,
    },
    {
      key: "quantity",
      label: t`Kol`,
      maxWidth: 40,
    },
    {
      key: "priceCents",
      label: t`Znesek`,
      maxWidth: 100,
    },
    {
      key: "source",
      label: "",
      maxWidth: 100,
    },
  ];

  const renderCell = useCallback(
    (article: Article, columnKey: React.Key) => {
      const cellValue = article[columnKey as keyof Article];

      switch (columnKey) {
        case "priceCents": {
          const hasDiscount =
            article.discountAmountCents != null ||
            article.discountPercentage != null;

          if (hasDiscount) {
            const finalPriceCents = calculateFinalArticlePrice(article);

            const finalPriceCentsFormatted = formatCurrency({
              amount: finalPriceCents / 100,
              currency,
              locale,
              fractionDigits: 3,
            });

            const quantityPriceFormatted = formatCurrency({
              amount: (article.priceCents * article.quantity) / 100,
              currency,
              locale,
              fractionDigits: 3,
            });

            return (
              <div className="flex flex-col justify-center">
                <p className="text-lg">{finalPriceCentsFormatted}</p>
                <p className="text-xs text-gray-500 line-through">
                  {quantityPriceFormatted}
                </p>
              </div>
            );
          }

          const priceFormatted = formatCurrency({
            amount: (article.priceCents * article.quantity) / 100,
            currency,
            locale,
            fractionDigits: 3,
          });

          return <p className="text-sm sm:text-lg">{priceFormatted}</p>;
        }
        case "source": {
          const isSelectedApptService = selectedAppointmentServices.some(
            (service) =>
              service.userAppointmentId === article.userAppointmentId,
          );

          if (isSelectedApptService)
            return (
              <div className="flex w-full items-center justify-center">
                <RiCalendarEventFill className="text-[1rem] sm:text-[1.5rem]" />
              </div>
            );
          return (
            <div className="flex w-full items-center justify-center">
              <ReceiptText className="h-6 w-6" />
            </div>
          );
        }
        default: {
          return <p className="text-sm sm:text-lg">{cellValue}</p>;
        }
      }
    },
    [currency, form.getValues().articles],
  );

  return (
    <Table
      selectionMode="single"
      selectedKeys={selectedArticleKey ? [selectedArticleKey] : []}
      onSelectionChange={onSelectionChange}
      aria-label={t`Seznam artiklov`}
      shadow="none"
      classNames={{
        wrapper: "md:px-0",
      }}
      // disabledKeys={selectedAppointmentServices?.map((service) =>
      //   service.serviceId.toString(),
      // )}
      className="flex-1"
      // isStriped
    >
      <TableHeader columns={columns}>
        {(column) => (
          <TableColumn
            width={column.maxWidth}
            key={column.key}
            className="uppercase"
          >
            {column.label}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        emptyContent={t`Seznam artiklov je prazen`}
        items={form.getValues().articles}
        loadingContent={
          <div className="flex items-center justify-center gap-4">
            <CircularProgress />
            <p>{t`Nalaganje...`}</p>
          </div>
        }
        loadingState={loadingState}
      >
        {(item) => (
          <TableRow key={item.key}>
            {(columnKey) => {
              return <TableCell>{renderCell(item, columnKey)}</TableCell>;
            }}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
